import axios from '../../utils/axiosWrapper'
import environment from '../../environment'
import ErrorCheck from '../../utils/errorCheck'
// import { formatSort } from '../../utils/naturalSort'

const serverUrl = environment._serverUrl

// US 9153 - On Room Page (after Unit is selected), call /api/Location/RoomMetadata/{unitId}
export const fetchRoomMetadata = (authToken, siteId, unitId) => (dispatch) => {
  const urlForAuthUser =
    unitId !== undefined
      ? serverUrl + `Location/RoomMetadata/?UnitId=${unitId}`
      : serverUrl + `Location/RoomMetadata`
  dispatch({ type: 'rooms/FETCH_ROOM_METADATA' })
  axios({
    method: 'get',
    url: urlForAuthUser,
    headers: {
      authorization: authToken,
      siteId,
      Pragma: 'no-cache',
    },
  })
    .catch(function (error) {
      ErrorCheck(error)
      console.log(error.response)
    })
    .then(function (response) {
      if (response === undefined) {
        dispatch({
          type: 'rooms/FETCH_ROOM_METADATA_RECEIVED',
          payload: [],
        })
      } else {
        response.data.sort((a, b) => {
          let x = a.displayName.toUpperCase(),
            y = b.displayName.toUpperCase()
          return x === y ? 0 : x > y ? 1 : -1
        })
        dispatch({
          type: 'rooms/FETCH_ROOM_METADATA_RECEIVED',
          payload: response.data,
        })
      }
    })
}
