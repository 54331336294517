import axios from '../../utils/axiosWrapper'
import environment from '../../environment'
import ErrorCheck from '../../utils/errorCheck'
import { filterAssignments } from '../../utils/filterAssignments'

const serverUrl = environment._serverUrl

export const fetchStaffPool = (authToken, siteId) => (dispatch) => {
  const urlForAuthUser = serverUrl + 'User/StaffPool'

  dispatch({ type: 'assignments/FETCH_STAFF_POOL' })
  axios({
    method: 'get',
    url: urlForAuthUser,
    headers: {
      authorization: authToken,
      siteId,
      Pragma: 'no-cache',
    },
  })
    .then(function (response) {
      dispatch({
        type: 'assignments/FETCH_STAFF_POOL_RECEIVED',
        payload: response.data,
      })
    })
    .catch(function (error) {
      ErrorCheck(error)
      dispatch({
        type: 'assignments/FETCH_STAFF_POOL_ERROR',
        payload: error.response,
      })
    })
}

export const setSelectedStaff = (staffList) => (dispatch) => {
  dispatch({
    type: 'assignments/SET_SELECTED_STAFF',
    payload: staffList,
  })
}

export const staffSearchResults = (staffList) => (dispatch) => {
  dispatch({
    type: 'assignments/SEARCH_RESULTS_STAFF',
    payload: staffList,
  })
}

export const clearAllSelectedStaff = () => (dispatch) => {
  dispatch({
    type: 'assignments/CLEAR_ALL_SELECTED_STAFF',
  })
}

export const getStaffAssignmentMetadata =
  ({ authorization, siteId }) =>
  async (dispatch) => {
    dispatch({
      type: 'assignments/CLEAR_BUBBLE_METADATA',
    })
    dispatch({
      type: 'assignments/FETCH_BUBBLE_METADATA',
    })
    const urlForAssignments = `${serverUrl}Assignment/GetBySite/${siteId}`
    try {
      const response = await axios({
        method: 'get',
        url: urlForAssignments,
        headers: {
          authorization,
          siteId,
          Pragma: 'no-cache',
        },
      })
      const filteredData = filterAssignments(response.data)

      dispatch({
        type: 'assignments/FETCH_BUBBLE_METADATA_RECEIVED',
        payload: filteredData,
      })
    } catch (error) {
      dispatch({
        type: 'assignments/FETCH_BUBBLE_METADATA_ERROR',
        payload: error,
      })
    }
  }
