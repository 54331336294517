/**
 * Styling Imports
 * */
import styled from 'styled-components'

const Documentation = () => {
  return (
    <>
      <StyledHeader>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div className="projectTitle" style={{ minWidth: '149px' }}>
            <img
              alt="Care Team Logo"
              style={{
                height: '19px',
                display: 'inline',
                marginRight: '5px',
                width: '149px',
                marginBottom: '5px',
              }}
              src={require('../reusableComponents/CareTeamLogo.svg')}
            />
          </div>
          <div style={{ lineHeight: 4.5, color: 'white' }}>
            Developer Portal
          </div>
        </div>
      </StyledHeader>
    </>
  )
}

const StyledHeader = styled.div`
  width: 100%;
  background-color: #03173e;
  display: flex;
  flex-direction: row;
  .projectTitle {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 20px;
    padding-bottom: 20px;
    user-select: none;
    color: white;
    display: flex;
    flex-wrap: no-wrap;
  }
  .navItemText {
    color: white;
  }
  .neu-tab--is-active {
    border-color: #0085ca;
  }
`

export default Documentation
