const initialState = {
  sites: null,
  selectedSiteId: '',
  fetching: false,
  error: null,
  // TODO: Investigate instances of selectedSiteAdminView
  selectedSiteAdminView: {},
  adminUserSiteError: null,
  fetchingSiteMetaData: false,
  siteMetaData: [],
  siteMetaDataError: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'user/FETCH_SITES_ERROR':
      return { ...state, error: action.payload, fetching: false }
    case 'sites/SET_SELECTED_SITE':
      return { ...state, selectedSiteId: action.payload }
    case 'sites/FETCH_SITES':
      return { ...state, fetching: true }
    case 'location/FETCHING_SITE_META_DATA':
      return { ...state, fetchingSiteMetaData: action.payload }
    case 'location/FETCH_SITE_META_DATA_RECEIVED':
      return {
        ...state,
        siteMetaData: action.payload,
        fetchingSiteMetaData: false,
      }
    case 'location/FETCH_SITE_META_DATA_ERROR':
      return {
        ...state,
        siteMetaDataError: action.payload,
        fetchingSiteMetaData: false,
      }
    case 'sites/SET_SELECTED_ADMIN_VIEW_SITE':
      // TODO: Investigate instances of selectedSiteAdminView
      return { ...state, selectedSiteAdminView: action.payload }
    case 'sites/FETCH_SITES_RECEIVED':
      const sortedSites = action.payload.sort((a, b) => {
        if (a.displayName < b.displayName) {
          return -1
        }
        if (a.displayName > b.displayName) {
          return 1
        }
        return 0
      })
      return {
        ...state,
        sites: sortedSites,
        fetching: false,
      }
    case 'user/FETCH_ADMIN_USER_SITES_ERROR':
      // TODO: Investigate instances of selectedSiteAdminView
      return { ...state, adminUserSiteError: action.payload }
    default:
      return state
  }
}
