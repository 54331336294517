import styled from 'styled-components'

export const OverwriteModal = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  border: solid 1px;
  left: 30%;
  top: 30%;
  height: 213px;
  border-radius: 5px;
  width: 420px;
  z-index: 2;
`
export const ButtonsDiv = styled.div`
  display: flex !important;
  justify-content: flex-end;
  margin-top: 5px;
  padding-right: 10px;
`
export const ConfirmText = styled.p`
  font-size: 16px;
  line-height: 25px !important;
  letter-spacing: 0.2p;
  padding-right: 2px !important;
  padding-bottom: 20px !important;
`
