/**
 * Internal Imports
 * */
import Loading from '../reusableComponents/neutronComponents/Spinner'
/**
 * Styling Imports
 * */
import { Container } from './staff.styles'

const SidePanel = ({ dispatch, children, loading, ...props }) => {
  if (loading) {
    return (
      <Container className="calcHeight">
        <div
          style={{
            height: '300px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loading square="100px" />
        </div>
      </Container>
    )
  } else {
    return <Container className="calcHeight">{children}</Container>
  }
}

export default SidePanel
