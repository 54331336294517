const initialState = {
  stagedStaffSlots: [],
  saving: false,
  error: null,
  editing: false,
  assigning: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'stagedStaffSlots/DISCARD_SLOTS':
      return {
        ...state,
        stagedStaffSlots: [],
      }
    case 'stagedStaffSlots/EDITING_SLOTS':
      return { ...state, editing: action.payload }
    case 'stagedStaffSlots/PUBLISH':
      return { ...state, saving: action.payload }
    case 'stagedStaffSlots/PUBLISH_ERROR':
      return { ...state, error: action.payload }
    case 'stagedStaffSlots/ASSIGNING':
      return { ...state, assigning: action.payload }
    case 'stagedStaffSlots/ASSIGN_SLOTS':
      const newAssignments = action.payload.data.map((_slot) => {
        return Object.assign({}, _slot, {
          action: action.payload.assignmentType,
        })
      })

      const filteredAssignments = [...state.stagedStaffSlots]

      newAssignments.forEach((assignment) => {
        // if there are no assignments push new assignment
        if (filteredAssignments.length === 0) {
          filteredAssignments.push(assignment)
        }
        const match = filteredAssignments.some(
          (_assignment) =>
            _assignment.uuid === assignment.uuid &&
            _assignment.manifest.AssignmentSlot.SystemName ===
              assignment.manifest.AssignmentSlot.SystemName,
        )

        // push newest assignment if there's a match
        if (match) {
          filteredAssignments.findIndex(
            (_assignment) =>
              _assignment.uuid === assignment.uuid &&
              _assignment.manifest.AssignmentSlot.SystemName ===
                assignment.manifest.AssignmentSlot.SystemName,
          )

          filteredAssignments.splice(
            filteredAssignments.findIndex(
              (_assignment) =>
                _assignment.uuid === assignment.uuid &&
                _assignment.manifest.AssignmentSlot.SystemName ===
                  assignment.manifest.AssignmentSlot.SystemName,
            ),
            1,
            assignment,
          )
          //
        } else {
          filteredAssignments.push(assignment)
        }
      })

      return {
        ...state,
        stagedStaffSlots: [...filteredAssignments],
      }

    default:
      return state
  }
}
