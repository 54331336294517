import styled from 'styled-components'

export const Parent = styled.div`
  width: 100px;
  height: 160px;
  top: -170px;
  background-color: white;
  position: absolute;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 99;
  box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.1);
`
export const Triangle = styled.div`
  width: 10px;
  height: 10px;
  background-color: white;
  position: absolute;
  transform: rotate(-135deg);
  top: 154px;
  left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 0;
`
export const Content = styled.div`
  postition: absolute;
  width: 110px;
  z-index: 4;
  height: 160px;
  overflow: scroll;
  overflow-x: hidden;
  background-color: white;
  z-index: 9099;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
export const HiddenDiv = styled.div`
  overflow: hidden;
`

export const Time = styled.div`
  padding: 10px 0 ${({ isLast }) => (isLast ? '15px' : '10px')};
  cursor: pointer;
  font-family: sans-serif;
  box-border: border-box;
  text-align: center;
  border-bottom: ${({ isLast }) =>
    isLast ? 'none' : '1px solid rgba(0, 0, 0, 0.3)'};
  :hover {
    border: 1px solid cyan;
  }
`
export const Gradient = styled.div`
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 100%;
  height: 20px;
  position: absolute;
  z-index: 9199;
  bottom: 0;
`
