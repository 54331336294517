import axios from '../../utils/axiosWrapper'
import environment from '../../environment'
import ErrorCheck from '../../utils/errorCheck'

const serverUrl = environment._serverUrl
const developmentEnvironment = process.env.REACT_APP_ENV
const devENV = developmentEnvironment.toLowerCase()

export const fetchPreferredStaffPool =
  ({ authToken, fsDepartmentIds, siteId }) =>
  (dispatch, getState) => {
    const facilityCoid = getState().sites.sites.find(
      (site) => site.siteId === siteId,
    ).facilityCoid
    const baseURL = serverUrl.replace('api/', '')
    let urlForAuthUser = `${baseURL}adapter/fs/preferredusers`

    let fakeDataEndpoint =
      serverUrl + `User/preferred-users-mock/${fsDepartmentIds[0]}`
    if (authToken && fsDepartmentIds.length > 0 && facilityCoid) {
      dispatch({
        type: 'assignments/FETCH_PREFERRED_STAFF_POOL_FETCHING',
        payload: true,
      })
      if (devENV === 'local' && facilityCoid === '04446') {
        axios({
          method: 'get',
          url: fakeDataEndpoint,
          headers: {
            authorization: authToken,
            siteId,
            Pragma: 'no-cache',
          },
        })
          .then(function (response) {
            dispatch({
              type: 'assignments/SPECIFIC_PREFERRED_STAFF',
              payload: response.data,
            })
            dispatch({
              type: 'assignments/FETCH_PREFERRED_STAFF_POOL_FETCHING',
              payload: false,
            })
          })
          .catch(function (error) {
            ErrorCheck(error)
            console.log('error', error)

            dispatch({
              type: 'assignments/FETCH_PREFERRED_STAFF_POOL_ERROR',
              payload: error.response,
            })
          })
      } else {
        axios({
          method: 'post',
          url: urlForAuthUser,
          data: JSON.stringify(fsDepartmentIds),
          headers: {
            authorization: authToken,
            siteId,
            Pragma: 'no-cache',
            'Access-Control-Allow-Origin': '*',
          },
        })
          .then(function (response) {
            dispatch({
              type: 'assignments/SPECIFIC_PREFERRED_STAFF',
              payload: response.data,
            })
            dispatch({
              type: 'assignments/FETCH_PREFERRED_STAFF_POOL_FETCHING',
              payload: false,
            })
          })
          .catch(function (error) {
            ErrorCheck(error)
            console.log('error', error)
            dispatch({
              type: 'assignments/FETCH_PREFERRED_STAFF_POOL_FETCHING',
              payload: false,
            })
            dispatch({
              type: 'assignments/FETCH_PREFERRED_STAFF_POOL_ERROR',
              payload: error.response,
            })
          })
      }
      dispatch({
        type: 'assignments/FETCH_PREFERRED_STAFF_POOL_FETCHING',
        payload: false,
      })
    } else {
      dispatch({
        type: 'assignments/SPECIFIC_PREFERRED_STAFF',
        payload: [],
      })
    }
  }

export const fetchAllPreferredStaffPool =
  ({ authToken, siteId }) =>
  (dispatch, getState) => {
    const facilityCoid = getState().sites.sites.find(
      (site) => site.siteId === siteId,
    ).facilityCoid
    const baseURL = serverUrl.replace('api/', '')

    let urlForAuthUser = `${baseURL}adapter/fs/preferredusers`
    let fakeDataEndpoint = serverUrl + `User/preferred-users-mock/-1`

    if (authToken && facilityCoid) {
      dispatch({
        type: 'assignments/FETCH_ALL_PREFERRED_STAFF_POOL_FETCHING',
        payload: true,
      })
      if (devENV === 'local' && facilityCoid === '04446') {
        axios({
          method: 'get',
          url: fakeDataEndpoint,
          headers: {
            authorization: authToken,
            siteId,
            Pragma: 'no-cache',
          },
        })
          .then(function (response) {
            dispatch({
              type: 'assignments/ALL_PREFERRED_STAFF',
              payload: response.data,
            })
            dispatch({
              type: 'assignments/FETCH_ALL_PREFERRED_STAFF_POOL_FETCHING',
              payload: false,
            })
          })
          .catch(function (error) {
            ErrorCheck(error)
            console.log('error', error)
            dispatch({
              type: 'assignments/FETCH_ALL_PREFERRED_STAFF_POOL_FETCHING',
              payload: false,
            })
            dispatch({
              type: 'assignments/FETCH_ALL_PREFERRED_STAFF_POOL_ERROR',
              payload: error.response,
            })
          })
      } else {
        axios({
          method: 'post',
          url: urlForAuthUser,
          data: JSON.stringify(['-1']),
          headers: {
            authorization: authToken,
            siteId,
            Pragma: 'no-cache',
          },
        })
          .then(function (response) {
            dispatch({
              type: 'assignments/ALL_PREFERRED_STAFF',
              payload: response.data,
            })
            dispatch({
              type: 'assignments/FETCH_ALL_PREFERRED_STAFF_POOL_FETCHING',
              payload: false,
            })
          })
          .catch(function (error) {
            ErrorCheck(error)
            console.log('error', error)
            dispatch({
              type: 'assignments/FETCH_ALL_PREFERRED_STAFF_POOL_ERROR',
              payload: error.response,
            })
          })
      }
      dispatch({
        type: 'assignments/FETCH_ALL_PREFERRED_STAFF_POOL_FETCHING',
        payload: false,
      })
    } else {
      return
    }
  }
