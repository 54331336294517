import { v4 as uuid } from 'uuid'
import _ from 'lodash'
const initialState = {
  roomMetadata: [],
  fetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'rooms/FETCH_ROOM_METADATA':
      return {
        ...state,
        fetching: true,
      }
    case 'rooms/FETCH_ROOM_METADATA_RECEIVED':
      return {
        ...state,
        roomMetadata: action.payload,
        fetching: false,
      }
    case 'rooms/CLEAR_ROOM_METADATA':
      return {
        ...state,
        roomMetadata: [],
      }
    default:
      return state
  }
}
