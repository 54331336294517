/**
 * External Imports
 * */
import { useState, useEffect } from 'react'
import { Column, Table, AutoSizer } from 'react-virtualized'
import { connect, useSelector } from 'react-redux'
import { useAnalyticsApi } from '@shared-web-analytics/react/dist'

/**
 * Internal Imports
 * */
import { setSelectedStaff } from '../../redux/actions/staffPool'
import StaffTooltip from './StaffTooltip'
import Events from '../../analytics/events'
/**
 * Styling Imports
 * */
import Flex from '../reusableComponents/styleLibrary'
import './style.css'
import { RowContainer, RowCard, StaffList, StaffRole } from './staff.styles'

const StaffTable = (props) => {
  const [selectedRows, setSelectedRows] = useState([])
  const [startIndex, setStartIndex] = useState(0)
  const [, setAuthToken] = useState()
  const [, setSiteId] = useState()
  const [, setStaffSlots] = useState()

  useEffect(() => {
    setSelectedRows(props.staffPool.selectedStaffMember)
    setAuthToken(props.authToken)
    setSiteId(props.siteId)
    setStaffSlots(props.staffSlots)
  }, [
    props.staffPool.selectedStaffMember,
    props.authToken,
    props.siteId,
    props.staffSlots,
  ])

  const toToggledSelection = (rowData) => {
    const index = selectedRows.indexOf(rowData)
    setStartIndex(index)
    const selectedRow = index === -1 ? [rowData] : []
    props.dispatch(setSelectedStaff(selectedRow))
  }

  const cellRenderer = (index) => {
    const item = props.list[index]
    const itemIsSelected = selectedRows.indexOf(item) !== -1
    return itemIsSelected ? 'selectedCard' : ''
  }

  const { list } = props
  const { logTrackingEvent } = useAnalyticsApi()
  const orgId = useSelector((state) => state.user.userSettings.facilityCoid)

  /**
   * Tracking
   */
  const trackingClicks = () => {
    const ctaLastPath = localStorage.getItem('ctaLastPath')
    if (
      !props.isDraftAssignment &&
      ctaLastPath.includes('locationassignments')
    ) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[0].Name,
          eventName: Events.Locations.Screen[0].Events.StaffMemberSelectClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (
      props.isDraftAssignment &&
      ctaLastPath.includes('locationassignments')
    ) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[1].Name,
          eventName: Events.Locations.Screen[1].Events.StaffMemberSelectClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (ctaLastPath.includes('patientassignments')) {
      logTrackingEvent([
        {
          module: Events.Patients.Module,
          screen: Events.Patients.Screen.Name,
          eventName: Events.Patients.Screen.Events.StaffMemberSelectClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    }
  }
  return list ? (
    <AutoSizer style={{ marginTop: '15px', height: '100%' }}>
      {({ width, height }) => (
        <Table
          scrollToRow={startIndex}
          sort={props.sortFunction}
          sortBy={props.sortBy}
          sortDirection={props.sortDirection}
          headerClassName={''}
          width={263}
          height={height}
          headerHeight={props.headerHeight}
          rowHeight={75}
          rowCount={props.list.length}
          rowGetter={({ index }) => props.list[index]}
        >
          <Column
            label="Name"
            dataKey="lastName"
            width={240}
            cellRenderer={(cellData) => {
              const isSelected = cellRenderer(cellData.rowIndex)
              return (
                <RowCard
                  className=""
                  onClick={() => {
                    trackingClicks()
                    return toToggledSelection(cellData.rowData)
                  }}
                >
                  <RowContainer className={isSelected}>
                    <Flex direction="column">
                      <StaffList className="neu-text">
                        {cellData.rowData.lastName},{' '}
                        {cellData.rowData.firstName}
                      </StaffList>
                      <StaffRole className="neu-text--support small pl2 pb2">
                        {cellData.rowData &&
                          cellData.rowData.clinicalRole &&
                          cellData.rowData.clinicalRole.abbreviation}
                      </StaffRole>
                    </Flex>
                    {cellData.rowData.assignmentCount &&
                      !props.isDraftAssignment && (
                        <Flex
                          style={{
                            alignSelf: 'center',
                            marginRight: '10px',
                            height: '56px',
                          }}
                        >
                          {props.staffPool.bubbleMetadata && (
                            <StaffTooltip
                              staffPool={props.staffPool}
                              bubbleMetadata={
                                props.staffPool.bubbleMetadata
                                  ? props.staffPool.bubbleMetadata
                                  : ''
                              }
                              rowData={cellData.rowData}
                              cellData={cellData}
                              dispatch={props.dispatch}
                            />
                          )}
                        </Flex>
                      )}
                  </RowContainer>
                </RowCard>
              )
            }}
          />
        </Table>
      )}
    </AutoSizer>
  ) : (
    <div></div>
  )
}

export default connect((state) => ({
  staffPool: state.staffPool,
  siteId: state.sites.selectedSiteId,
  authToken: state.user.token,
}))(StaffTable)
