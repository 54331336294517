/**
 * External Imports
 * */
import { useAnalyticsApi } from '@shared-web-analytics/react/dist'
import { useSelector } from 'react-redux'

/**
 * Internal Imports
 * */
import Flex from '../reusableComponents/styleLibrary'
import printPatients from '../reusableComponents/printPatients'
import PrintLocations from '../reusableComponents/printLocations'
import AssignedLocationDisplay from '../manage/users/AssignedLocationDisplay'
import { dischargePatient } from '../../redux/actions/patients'
import { getSingleLocation } from '../../redux/actions/locations'
import { getClinicalRolesForAddUser } from '../../redux/actions/groupAndStaffSlotMetaData'
import { UserRow, ColumnDiv, DisplayNameDiv, LocationDiv } from './table.styles'
import Events from '../../analytics/events'

const DataList = (props) => {
  const { logTrackingEvent } = useAnalyticsApi()
  const orgId = useSelector((state) => state.user.userSettings.facilityCoid)
  let listData = props.list
  if (props.path === 'users') {
    if (props.list.length < 1) {
      return (
        <div
          style={{ marginLeft: '30px', fontSize: '18px', fontWeight: '500' }}
        >
          No users found.
        </div>
      )
    }
  } else if (
    props.path === 'locations' &&
    props.list &&
    props.list.length < 1
  ) {
    return (
      <div style={{ marginLeft: '30px', fontSize: '18px', fontWeight: '500' }}>
        No units found in this Site
      </div>
    )
  } else {
    listData = props.list && props.list
  }

  const handleSlidingPane = (data) => {
    if (props.path === 'users') {
      const authToken = props.authToken
      const siteId = data.siteId
      props.dispatch(
        getClinicalRolesForAddUser({
          authorization: authToken,
          siteId,
        }),
      )
      props.dispatch({
        type: 'ui/MANAGE_SLIDING_PANE',
        payload: {
          open: true,
          source: props.path,
          data: data,
        },
      })
    } else {
      props.dispatch(
        getSingleLocation({
          authToken: props.authToken,
          siteId:
            props.path === 'sites'
              ? data.siteId
              : props.selectedAdminSite?.value,
          locationId: data.id,
        }),
      )
      props.dispatch({
        type: 'ui/MANAGE_SLIDING_PANE',
        payload: {
          open: true,
          source: props.path,
          data: data,
        },
      })
    }
  }

  return (
    listData?.length > 0 &&
    listData.map((data, index) => {
      let formattedData
      switch (props.path) {
        case 'users':
          formattedData = [
            <div>
              <div
                className="neu-text--body neu-text--bold neu-text--gray-90"
                style={{
                  color: '#1F2532',
                  fontFamily: 'HCA-Mark, Arial, sans-serif',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                }}
              >
                {data.lastName + ', ' + data.firstName}
              </div>
              <div
                style={{
                  color: '#1F2532',
                  fontFamily: 'HCA-Mark, Arial, sans-serif',
                  fontSize: '16px',
                  opacity: '0.7',
                }}
              >
                {data.hca34.toUpperCase()}
              </div>
            </div>,
            <div
              style={{
                color: '#1F2532',
                fontFamily: 'HCA-Mark, Arial, sans-serif',
                fontSize: '16px',
                opacity: '0.9',
              }}
            >
              {(data.clinicalRole && data.clinicalRole.displayName) || ''}
            </div>,
            <AssignedLocationDisplay
              assignedLocations={data.assignedLocations}
            />,
          ]
          break
        case 'patientassignments':
          formattedData = printPatients(data, props)

          break
        case 'locationassignments':
          formattedData = PrintLocations(data, props)

          break
        case 'sites':
          formattedData = [
            <DisplayNameDiv>{data.displayName}</DisplayNameDiv>,
            <ColumnDiv>
              <LocationDiv
                onClick={() =>
                  props.handleLocationsTableSelection(data, 'Units')
                }
              >
                {data.numberOfUnits}{' '}
                {data.numberOfUnits === 1 ? (
                  <span>Unit</span>
                ) : (
                  <span>Units</span>
                )}
              </LocationDiv>
              {data.numberOfHiddenUnits > 0 && (
                <div>({data.numberOfHiddenUnits} Hidden)</div>
              )}
            </ColumnDiv>,
            <ColumnDiv>
              <LocationDiv
                onClick={() =>
                  props.handleLocationsTableSelection(data, 'Rooms')
                }
              >
                {data.totalNumberOfRooms}{' '}
                {data.totalNumberOfRooms === 1 ? (
                  <span>Room</span>
                ) : (
                  <span>Rooms</span>
                )}
              </LocationDiv>
              {data.numberOfHiddenRooms > 0 && (
                <div>({data.numberOfHiddenRooms} Hidden)</div>
              )}
            </ColumnDiv>,
            <ColumnDiv>
              <LocationDiv
                onClick={() =>
                  props.handleLocationsTableSelection(data, 'Beds')
                }
              >
                {data.totalNumberOfBeds}{' '}
                {data.totalNumberOfBeds === 1 ? (
                  <span>Bed</span>
                ) : (
                  <span>Beds</span>
                )}
              </LocationDiv>
              {data.numberOfHiddenBeds > 0 && (
                <div>({data.numberOfHiddenBeds} Hidden)</div>
              )}
            </ColumnDiv>,
          ]
          break
        case 'units':
          formattedData = [
            <DisplayNameDiv>{data.displayName}</DisplayNameDiv>,
            <ColumnDiv>
              <div>{data.status}</div>
            </ColumnDiv>,
            <ColumnDiv>
              <LocationDiv
                onClick={() =>
                  props.handleLocationsTableSelection(data, 'Rooms')
                }
              >
                {data.totalNumberOfRooms}{' '}
                {data.totalNumberOfRooms === 1 ? (
                  <span>Room</span>
                ) : (
                  <span>Rooms</span>
                )}
              </LocationDiv>
              {data.numberOfHiddenRooms > 0 && (
                <div>({data.numberOfHiddenRooms} Hidden)</div>
              )}
            </ColumnDiv>,
            <ColumnDiv>
              <LocationDiv
                onClick={() =>
                  props.handleLocationsTableSelection(data, 'Beds')
                }
              >
                {data.totalNumberOfBeds}{' '}
                {data.totalNumberOfBeds === 1 ? (
                  <span>Bed</span>
                ) : (
                  <span>Beds</span>
                )}
              </LocationDiv>
              {data.numberOfHiddenBeds > 0 && (
                <div>({data.numberOfHiddenBeds} Hidden)</div>
              )}
            </ColumnDiv>,
          ]
          break
        case 'rooms':
          formattedData = [
            <DisplayNameDiv>{data.displayName}</DisplayNameDiv>,
            <ColumnDiv>
              <div>{data.status}</div>
            </ColumnDiv>,
            <ColumnDiv>
              <LocationDiv
                onClick={() =>
                  props.handleLocationsTableSelection(data, 'Beds')
                }
              >
                {data.totalNumberOfBeds}{' '}
                {data.totalNumberOfBeds === 1 ? (
                  <span>Bed</span>
                ) : (
                  <span>Beds</span>
                )}
              </LocationDiv>
              {data.numberOfHiddenBeds > 0 && (
                <div>({data.numberOfHiddenBeds} Hidden)</div>
              )}
            </ColumnDiv>,
          ]
          break
        case 'beds':
          formattedData = [
            <DisplayNameDiv>{data.displayName}</DisplayNameDiv>,
            <ColumnDiv>
              <div>{data.status}</div>
            </ColumnDiv>,
          ]
          break
        case 'patients':
          formattedData = [
            <div
              style={{
                color: '#1F2532',
                fontFamily: 'HCA-Mark, Arial, sans-serif',
                fontSize: '18px',
                fontWeight: 'bold',
                paddingTop: '10px',
              }}
            >
              {data.bed}
            </div>,
            <div
              style={{
                display: 'column',
                fontSize: 12,
                color: '#1F2532',
                fontWeight: '500',
                paddingTop: '-10px',
              }}
            >
              <div style={{ fontWeight: 'bold' }}>{data.name}</div>
              <div>{data.meta}</div>
            </div>,
          ]
          break
        default:
          break
      }

      return (
        <UserRow
          last={index === listData.length - 1}
          key={
            props.path === 'locationassignments' ||
            props.path === 'patientassignments'
              ? `${data.location?.unit}:${data.location?.bed}:${index}`
              : index
          }
        >
          {formattedData.map((val, i) => {
            return (
              <div
                style={{
                  minHeight: '60px',
                  height: 'max-content',
                  fontWeight: '600',
                  paddingTop:
                    props.path === 'patients' ||
                    (props.path === 'locations' && i === 1)
                      ? '10px'
                      : '20px',
                  paddingBottom: '20px',
                  paddingLeft: i === 0 ? '20px' : '0',
                  flex: `0 0 ${
                    (((props.path === 'patients' &&
                      (props.authorizedGlobalAdmin ||
                        props.authorizedLocalAdmin)) ||
                    ((props.path === 'locationassignments' ||
                      props.path === 'patientassignments') &&
                      !props.ui.isDraftAssignment) ||
                    (props.path !== 'patients' &&
                      (props.authorizedGlobalAdmin ||
                        props.authorizedLocalAdmin))
                      ? 92
                      : 100) *
                      props.columns[i].multiplier) /
                      props.columns.reduce(
                        (acc, column) => acc + column.multiplier,
                        0,
                      ) || 0
                  }%`,
                  maxWidth:
                    i === 0 &&
                    props.path === 'locationassignments' &&
                    props.ui.departmentSelected === 'Nursing'
                      ? '175px'
                      : undefined,
                  ...(i > 1 && !(props.path === 'users')
                    ? { display: 'flex', justifyContent: 'center' }
                    : {}),
                }}
                key={
                  props.path === 'locationassignments' ||
                  props.path === 'patientassignments'
                    ? `${data.location?.bed}:${i}`
                    : `${index}:${i}`
                }
              >
                {val}
              </div>
            )
          })}
          {props.path === 'patients' &&
            (props.authorizedGlobalAdmin || props.authorizedLocalAdmin) && (
              <div
                className="cursor"
                style={{
                  paddingTop: '20px',
                  paddingRight: '40px',
                  color: '#cc2c04',
                  fontWeight: '800',
                  position: 'absolute',
                  right: 0,
                }}
                onClick={() => {
                  logTrackingEvent([
                    {
                      module: Events.Manage.Module,
                      screen: Events.Manage.Screen[2].Name,
                      eventName:
                        Events.Manage.Screen[2].Events.RemoveButtonClick,
                      eventType: 'action',
                      org: {
                        orgId: orgId,
                        orgLevel: 'Facility',
                      },
                    },
                  ])

                  props.dispatch(
                    dischargePatient(
                      props.authToken,
                      props.selectedAdminSite?.value,
                      data.id,
                      data.unitId,
                    ),
                  )
                }}
              >
                Remove
              </div>
            )}
          {(props.path === 'locationassignments' ||
            props.path === 'patientassignments') &&
            !props.ui.isDraftAssignment && (
              <Flex
                style={{
                  justifyContent: 'flex-end',
                  position: 'absolute',
                  right: 0,
                  height: '100%',
                }}
                onClick={() => {
                  props.dispatch({
                    type: 'ui/ASSIGNMENT_SLIDING_PANE',
                    payload: {
                      open: true,
                      source: props.path.slice(0, props.path.length - 1),
                      data: data.slidingPaneData,
                      userExists: false,
                    },
                  })
                }}
              >
                <div style={{ width: 'max-content', padding: 10 }}>
                  <span
                    style={{
                      width: '100%',
                      textAlign: 'right',
                      cursor: 'pointer',
                      paddingRight: '10px',
                      marginTop: '10px',
                    }}
                    className="neu-text--h5 neu-text--gray-40"
                  >
                    ...
                  </span>
                </div>
              </Flex>
            )}
          {props.path !== 'patients' &&
            (props.authorizedGlobalAdmin || props.authorizedLocalAdmin) && (
              <div
                className="cursor"
                style={{
                  paddingTop: '10px',
                  paddingRight: '20px',
                  color: 'rgba(5,50,134)',
                  fontFamily: 'HCA-Mark, Arial, sans-serif',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  display: 'flex',
                  position: 'absolute',
                  right: 0,
                }}
                onClick={() => {
                  handleSlidingPane(data)
                }}
              >
                <i
                  className="material-icons"
                  style={{ paddingTop: '5px', height: '14px' }}
                >
                  edit
                </i>
                <div style={{ marginTop: '10px' }}>Edit</div>
              </div>
            )}
        </UserRow>
      )
    })
  )
}

export default DataList
