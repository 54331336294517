import axios from '../../utils/axiosWrapper'
import _ from 'lodash'
import environment from '../../environment'
import ErrorCheck from '../../utils/errorCheck'
import { formatDropdownData } from '../../utils/formatDropdownData'
import { getGlobalUserSettings } from '../actions/user'

const serverUrl = environment._serverUrl

export const selectSite = (selSiteId) => (dispatch) => {
  dispatch({
    type: 'sites/SET_SELECTED_SITE',
    payload: selSiteId,
  })
}

export const getUserSites =
  ({ user34, authToken }) =>
  async (dispatch) => {
    const upperCase34 = user34.toUpperCase()
    const endpointForAuthUserFacilities =
      serverUrl + `User/GetUserFacilities/${upperCase34}`
    const endpointForAuthUser = serverUrl + `User/GetUserSites/${upperCase34}`

    // Make a request for a user with a given ID
    dispatch({ type: 'sites/FETCH_SITES' })

    try {
      // TODO: Remove in the future when divisions can be sourced from elsewhere
      const facilitiesResponse = await axios({
        method: 'get',
        url: endpointForAuthUserFacilities,
        headers: { Authorization: authToken, Pragma: 'no-cache' },
      })
      const response = await axios({
        method: 'get',
        url: endpointForAuthUser,
        headers: { Authorization: authToken, Pragma: 'no-cache' },
      })

      dispatch(getGlobalUserSettings({ authToken, sites: response.data }))

      const divisions = facilitiesResponse.data.map((site) => site.parent)
      const uniqDivisions = _.uniqBy(divisions, 'id')
      const sortedDivisions = _.sortBy(uniqDivisions, (d) => d.displayName)
      const allOption = [{ key: 'All', value: 'All', text: 'All' }]
      const formattedDivisions = allOption.concat(
        formatDropdownData(sortedDivisions),
      )
      const formattedDivisionsForPatientTab =
        formatDropdownData(sortedDivisions)

      dispatch({
        type: 'divisions/DIVISIONS_RECEIVED',
        payload: formattedDivisions,
      })
      dispatch({
        type: 'divisions/PATIENT_DIVISIONS_RECEIVED',
        payload: formattedDivisionsForPatientTab,
      })
      dispatch({
        type: 'sites/FETCH_SITES_RECEIVED',
        payload: response.data.map((site) => ({
          ...site,
          grandparent:
            facilitiesResponse.data.find(
              (facility) => facility.id === site.parent.id,
            )?.parent || null,
        })),
      })
    } catch (error) {
      ErrorCheck(error)
      dispatch({
        type: 'user/FETCH_SITES_ERROR',
        payload: error.response,
      })
    }
  }

export const fetchSiteMetadata =
  ({ facilityMetaDataString, authToken }) =>
  async (dispatch) => {
    const endpointForAuthUser = serverUrl + `Location/SiteMetadata`
    dispatch({
      type: 'location/FETCHING_SITE_META_DATA',
      payload: true,
    })

    try {
      const response = await axios({
        method: 'post',
        url: endpointForAuthUser,
        data: facilityMetaDataString,
        headers: {
          'Content-Type': 'application/json',
          authorization: authToken,
          Pragma: 'no-cache',
        },
      })
      dispatch({
        type: 'location/FETCH_SITE_META_DATA_RECEIVED',
        payload: response.data,
      })
    } catch (error) {
      ErrorCheck(error)
      dispatch({
        type: 'location/FETCH_SITE_META_DATA_ERROR',
        payload: error.response,
      })
      return
    }
  }
