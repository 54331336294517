/**
 * Styling Imports
 * */
import styled from 'styled-components'

export const ErrorSnackbar = (props) => {
  let statusCode
  let errorMessage
  if (props.stagedStaffSlotErrors !== null) {
    statusCode = props.stagedStaffSlotErrors.status
    errorMessage = props.stagedStaffSlotErrors.data.message
  } else {
    statusCode = ' '
    errorMessage = ' '
  }
  return (
    <div className={props.showHideErrorSnackbarClassName}>
      <Snackbar>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '10px',
            paddingTop: '10px',
          }}
        />
        <div
          style={{
            display: 'flex',
            paddingLeft: '10px',
          }}
        >
          <p className="neu-text" style={{ color: 'white' }}>
            {statusCode} Error.
            {errorMessage}. {' - '} Please refresh your browser and try again
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingLeft: '40px',
            }}
          >
            <span
              className="neu-text  cursor"
              style={{
                color: '#0085CA',
                fontSize: '16px',
                fontWeight: 'bold',
                paddingTop: '5px',
                paddingRight: '10px',
              }}
              onClick={props.hideSnackbar}
            >
              Close
            </span>
          </div>
        </div>
      </Snackbar>
    </div>
  )
}

export const GenericErrorSnackbar = (props) => {
  const getStyles = (type) => {
    switch (type) {
      case 'failure':
        return {
          background: '#F8DADE',
          icon: {
            color: '#971B2F',
            text: 'error',
          },
          messageText: {
            color: '#971B2F',
          },
        }

      case 'success':
        return {
          background: '#E8F4D6',
          icon: {
            color: '#285C4D',
            text: 'check_circle',
          },
          messageText: {
            color: '#285C4D',
          },
        }

      default:
        break
    }
  }
  const { background, icon, messageText } = getStyles(
    props.ui.flashMessage.type,
  )
  return (
    <div
      style={{
        transform: 'translate(-1093.000000, -570.000000)',
      }}
    >
      <Snackbar
        className="snackbar"
        style={{
          background: background,
          border: 'none',
          right: '20px',
          transform: 'translate(1081.000000, 561.000000)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: '10px',
            paddingTop: '10px',
          }}
        >
          <div
            style={{
              paddingLeft: '10px',
            }}
          >
            <span
              className="neu-text  cursor"
              style={{
                color: icon.color,
                fontSize: '16px',
                fontWeight: 'bold',
                paddingTop: '5px',
                paddingRight: '10px',
              }}
            >
              <i className="material-icons">{icon.text}</i>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              paddingLeft: '10px',
              paddingRight: '15px',
            }}
          >
            <p className="neu-text" style={{ color: messageText.color }}>
              {props.ui.flashMessage.messages[0]}
            </p>
          </div>
        </div>
      </Snackbar>
    </div>
  )
}

const Snackbar = styled.div.attrs({
  className: 'neu-snackbar',
})`
  background-color: #1f2532;
  display: flex;
  flex-direction: column;
  position: absolute;
  border: solid 1px;
  bottom: 36px;
  height: 50px;
  border-radius: 5px;
  width: max-content;
  z-index: 2;
`
