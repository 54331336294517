import store from '../redux/store'

const ErrorCheck = (error) => {
  if (
    error.response &&
    error.response.headers &&
    error.response.headers['www-authenticate'] &&
    error.response.headers['www-authenticate'].includes(
      'Bearer error="invalid_token", error_description="The token is expired"',
    )
  ) {
    store.dispatch({ type: 'ui/SET_TIMEOUT_ERROR', payload: true })
    return true
  } else {
    return false
  }
}

export default ErrorCheck
