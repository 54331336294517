import axios from '../../utils/axiosWrapper'
import environment from '../../environment'
import ErrorCheck from '../../utils/errorCheck'
import { fetchAdminLocationsByUnit } from './locations'

const serverUrl = environment._serverUrl

export const dischargePatient =
  (authToken, siteId, patientId, unitId) => (dispatch) => {
    const getUnitsUrl = serverUrl + `Patient/DischargedStatus/${patientId}`

    axios({
      method: 'post',
      url: getUnitsUrl,
      data: true,
      headers: {
        authorization: authToken,
        siteId,
        Pragma: 'no-cache',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        dispatch(fetchAdminLocationsByUnit(authToken, siteId, unitId))
      })
      .catch(function (error) {
        ErrorCheck(error)
        console.log(error.response)
      })
  }
