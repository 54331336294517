import { createSelector } from 'reselect'

export default createSelector(
  (state, props) => props,
  (state) => state.selectedStaffSlots.selectedStaffSlots,
  (state) => state.units,
  (state) => state.user,
  (state) => state.userGroups,
  (state) => state.patients,
  (state) => state.selectedStaffSlots,
  (state) => state.locations,
  (state) => state.staffPool,
  (state) => state.facilitySchedulerUsers,
  (state) => state.userSettings,
  (
    props,
    selectedStaffSlots,
    unitsState,
    userState,
    userGroupsState,
    stagedStaffSlots,
    patientsState,
    locationsState,
    staffPoolState,
    facilitySchedulerUsersState,
    userSettingsState,
  ) => {
    const selUserGroup = userGroupsState.userGroups.find((group) => {
      return group.id === userGroupsState.selectedUserGroupId
    })

    const selUserGroupName = selUserGroup ? selUserGroup.roleGroup : 'Nursing'

    let slotsPerRoleGroup = {}

    // format metadata to write the columns with the group names
    userGroupsState.userGroups.forEach((group) => {
      let groupName = group.roleGroup
      slotsPerRoleGroup[groupName] = group.assignmentSlots
    })

    const stagedStaffSlotErrors = stagedStaffSlots.error

    const staffSlots = selUserGroup ? slotsPerRoleGroup[selUserGroupName] : []

    const result = {
      ...props,
      selectedStaffSlots: selectedStaffSlots,
      units: unitsState,
      userGroups: userGroupsState,
      // filteredLocations: locationsState.locations,
      locations: locationsState,
      staffSlots: staffSlots,
      selectedUserGroupName: selUserGroupName,
      staffSlotsPerRoleGroup: slotsPerRoleGroup,
      stagedStaffSlotErrors: stagedStaffSlotErrors,
      user: userState,
      userSettings: userSettingsState,
      staffPool: staffPoolState,
      preferredStaff: facilitySchedulerUsersState,
    }

    return result
  },
)
