import styled from 'styled-components'
import { NeuSnackbar } from '@neutron/react'

export const Snackbar = styled(NeuSnackbar)`
  background-color: #bfe0f1 !important;
  width: 840px !important;
  flex: none !important;
  justify-content: center !important;
  position: absolute;
  left: 30%;
  top: 80%;
  border-radius: 5px;
  z-index: 2;
  height: 50px;
`
