import axios from '../../utils/axiosWrapper'
import environment from '../../environment'
import ErrorCheck from '../../utils/errorCheck'

const serverUrl = environment._serverUrl

export const fetchUnits = (authToken, siteId) => (dispatch) => {
  const getUnitsUrl = serverUrl + `Location/GetUnitsBySite?siteId=${siteId}`

  dispatch({ type: 'assignments/FETCH_UNITS' })
  axios({
    method: 'get',
    url: getUnitsUrl,
    headers: {
      authorization: authToken,
      siteId,
      Pragma: 'no-cache',
    },
  })
    .then(function (response) {
      dispatch({
        type: 'assignments/FETCH_UNITS_RECEIVED',
        payload: response.data,
      })
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        dispatch({
          type: 'assignments/FETCH_UNITS_RECEIVED',
          payload: [],
        })
      } else {
        ErrorCheck(error)
        dispatch({
          type: 'assignments/FETCH_UNITS_ERROR',
          payload: error.response,
        })
      }
    })
}

export const selectUnit = (unitIds) => (dispatch) => {
  dispatch({
    type: 'assignments/SET_SELECTED_UNIT',
    payload: unitIds,
  })
}

export const selectAdminUnit = (unitId) => (dispatch) => {
  dispatch({
    type: 'assignments/SET_SELECTED_ADMIN_UNIT',
    payload: unitId,
  })
}

export const getUnitMetaData =
  ({ siteId, authToken }) =>
  (dispatch) => {
    const endpointForAuthUser = serverUrl + `Location/UnitMetadata`
    dispatch({
      type: 'location/FETCHING_ALL_META_DATA',
      payload: true,
    })
    axios({
      method: 'get',
      url: endpointForAuthUser,
      headers: {
        authorization: authToken,
        siteId,
        Pragma: 'no-cache',
      },
    })
      .then(function (response) {
        dispatch({
          type: 'location/FETCH_UNIT_META_DATA_RECEIVED',
          payload: response.data,
        })
      })
      .catch(function (error) {
        console.log(error.response)
        ErrorCheck(error)
        dispatch({
          type: 'user/FETCH_UNIT_META_DATA_ERROR',
          payload: error.response,
        })
      })
  }

export const fetchAdminViewUnits = (authToken, siteId) => (dispatch) => {
  const getUnitsUrl = serverUrl + `Location/UnitMetadata`
  dispatch({ type: 'assignments/FETCH_ADMIN_VIEW_UNITS' })
  axios({
    method: 'get',
    url: getUnitsUrl,
    headers: {
      authorization: authToken,
      siteId,
      Pragma: 'no-cache',
    },
  })
    .then(function (response) {
      dispatch({
        type: 'assignments/FETCH_ADMIN_UNITS_RECEIVED',
        payload: response.data,
      })
    })
    .catch(function (error) {
      console.log(error.response)
      ErrorCheck(error)
      dispatch({
        type: 'assignments/FETCH_ADMIN_UNITS_ERROR',
        payload: error.response,
      })
    })
}

export const setUnitStatus =
  (authToken, status, unitId, siteId) => (dispatch) => {
    const getUnitsUrl = serverUrl + `Location/LocationStatusByUnit/${unitId}`

    axios({
      method: 'post',
      url: getUnitsUrl,
      data: status,
      headers: {
        authorization: authToken,
        siteId,
        Pragma: 'no-cache',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        dispatch(fetchUnits(authToken, siteId))
      })
      .catch(function (error) {
        ErrorCheck(error)
        console.log(error.response)
      })
  }
