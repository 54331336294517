const Spinner = (props) => {
  const getStroke = (n) => (n === '100px' ? '2' : '6')
  const getColor = (n) => (n === '100px' ? '#0085CA' : '#aaa')
  return (
    <div
      style={{
        alignSelf: 'center',
        maxWidth: '50%',
        marginLeft: props.square === '100px' ? '' : '-10px',
      }}
    >
      <svg
        className="neu-feedback--spinner"
        width={props.square}
        height={props.square}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="neu-feedback--spinner__fill"
          fill="none"
          stroke={getColor(props.square)}
          strokeWidth={getStroke(props.square)}
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    </div>
  )
}

const SmallLoading = (props) => {
  return (
    <div
      style={{
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '50%',
        height: '100%',
      }}
    >
      <Spinner square={props.square} />
    </div>
  )
}

export default SmallLoading
