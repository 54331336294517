import _ from 'lodash'

//To align to Patient tab just searching by patient name
export const filterPatients = (searchText, patients) => {
  if (!searchText) return patients
  if (typeof searchText !== 'string') return patients

  const patientNameSearch = patients.filter((patient) => {
    const patientName = patient && patient.name.toLowerCase()
    const sanitizedSearch = searchText.toLowerCase().trim()

    return patientName && patientName.includes(sanitizedSearch)
  })

  const results = _.uniq([...patientNameSearch])
  return results
}
