// export const setSelectedStaffSlot = assignmentData => dispatch => {
//     dispatch({
//         type: 'selectedStaffSlots/TOGGLE_LOCATION',
//         payload: assignmentData,
//     })
// }

export const setSelectedStaffSlot = (selectedStaffSlots) => (dispatch) => {
  dispatch({
    type: 'selectedStaffSlots/SELECTED_STAFF_SLOTS',
    payload: selectedStaffSlots,
  })
}

export const updateSelectedLocationsStaff = (staffMember) => (dispatch) => {
  dispatch({
    type: 'selectedStaffSlots/UPDATE_SELECTED_LOCATIONS_STAFF',
    payload: staffMember,
  })
}

export const clearAllSelectedLocationsStaff = () => (dispatch) => {
  dispatch({
    type: 'selectedStaffSlots/CLEAR_ALL_SELECTED_LOCATIONS_STAFF',
  })
}
