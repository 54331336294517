/**
 * Internal Imports
 * */
import Manage from '../manage/manage'

const ManageLinks = [
  {
    link: '/manage/users',
    navItemText: 'Users',
    //Current auth roles are user and admin
    allowedRoles: ['base'],
    component: (props) => {
      const customProps = props
      customProps.selPage = 'manage'
      customProps.selRoute = 'users'
      return () => <Manage {...customProps} />
    },
    navBarItem: false,
  },
  {
    link: '/manage/locations',
    navItemText: 'Locations',
    //Current auth roles are user and admin
    allowedRoles: ['CreateLocations', 'EditLocations'],
    component: (props) => {
      const customProps = props
      customProps.selPage = 'manage'
      customProps.selRoute = 'locations'
      return () => <Manage {...customProps} />
    },
    navBarItem: false,
  },
  {
    link: '/manage/patients',
    navItemText: 'Patients',
    //Current auth roles are user and admin
    allowedRoles: ['DischargePatients'],
    component: (props) => {
      const customProps = props
      customProps.selPage = 'manage'
      customProps.selRoute = 'patients'
      return () => <Manage {...customProps} />
    },
    navBarItem: false,
  },
]

export default ManageLinks
