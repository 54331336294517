import styled from 'styled-components'

export const SlidingPaneDiv = styled.div`
  background-color: white;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: -420px;
  width: 420px;
  z-index: 2;
`
