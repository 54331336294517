/**
 * Internal Imports
 * */
import Main from '../Main'
import ManageLinks from './ManageLinks'
import Manage from '../manage/manage'
import ReportsLinks from './ReportsLinks'
import Reports from '../reports/reports'

const navLinks = [
  {
    link: '/locationassignments',
    navItemText: 'Location',
    //Current auth roles are user and admin
    allowedRoles: ['base'],
    component: (props) => {
      const customProps = props
      customProps.selPage = 'locations'
      /*we are passing 2 props currently:
            disabledNav (true/false)
            and showHideDisabledModal(function to show/hide disabled modal)*/
      // attached in GateKeeper returnRoute function
      return () => <Main {...customProps} />
    },
    navBarItem: true,
  },
  {
    link: '/patientassignments',
    navItemText: 'Patient',
    allowedRoles: ['base'],
    component: (props) => {
      const customProps = props
      customProps.selPage = 'patients'
      /*we are passing 2 props currently:
            disabledNav (true/false)
            and showHideDisabledModal(function to show/hide disabled modal)*/
      // attached in GateKeeper returnRoute function
      return () => <Main {...customProps} />
    },
    navBarItem: true,
  },
  {
    link: '/reports/snapshot',
    subLinks: ReportsLinks.map(({ link }) => link),
    navItemText: 'Reports',
    allowedRoles: ['ViewReports'],
    component: (props) => {
      const customProps = props
      customProps.selPage = 'reports'
      /*we are passing 2 props currently:
            disabledNav (true/false)
            and showHideDisabledModal(function to show/hide disabled modal)*/
      // attached in GateKeeper returnRoute function
      return () => <Reports {...customProps} />
    },
    navBarItem: false,
  },
  {
    link: '/manage/users',
    subLinks: ManageLinks.map(({ link }) => link),
    navItemText: 'Manage',
    allowedRoles: ['CreateUser', 'CreateLocations', 'DischargePatients'],
    component: (props) => {
      const customProps = props
      customProps.selPage = 'manage'
      /*we are passing 2 props currently:
            disabledNav (true/false)
            and showHideDisabledModal(function to show/hide disabled modal)*/
      // attached in GateKeeper returnRoute function
      return () => <Manage {...customProps} />
    },
    navBarItem: false,
  },
]

export default navLinks
