import Flex from '../reusableComponents/styleLibrary'

/**
 * Styling Imports
 * */
import { IsolationStatusDiv } from './isolationStatusBadge.styles'

const IsolationStatusBadge = ({ isolationStatusList, keyProp }) => {
  return (
    <>
      {isolationStatusList?.map((isolation, index) => (
        <Flex position="row" key={keyProp + index}>
          <IsolationStatusDiv>
            {isolation !== 'undefined' ? <div>{isolation}</div> : ''}
          </IsolationStatusDiv>
        </Flex>
      ))}
    </>
  )
}

export default IsolationStatusBadge
