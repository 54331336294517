import _ from 'lodash'

export const filterUsers = (searchText, users) => {
  if (!searchText) return users
  if (typeof searchText !== 'string') return users
  const sanitizedSearch = searchText.toLowerCase().trim()

  const user34Search = users.filter((user) => {
    const hca34 = user && user.hca34.toLowerCase()

    return hca34 && hca34.includes(sanitizedSearch)
  })

  const nameSearch = users.filter((user) => {
    const firstName = user && user.firstName.toLowerCase()
    const lastName = user && user.lastName.toLowerCase()
    return (
      (firstName && firstName.startsWith(sanitizedSearch)) ||
      (lastName && lastName.startsWith(sanitizedSearch)) ||
      (firstName && `${firstName} ${lastName}`.startsWith(sanitizedSearch)) ||
      (firstName && `${lastName} ${firstName}`.startsWith(sanitizedSearch)) ||
      (firstName && `${lastName}, ${firstName}`.startsWith(sanitizedSearch)) ||
      (firstName && `${lastName},${firstName}`.startsWith(sanitizedSearch))
    )
  })

  const clinicalRoleSearch = users.filter((user) => {
    const clinicalRole =
      user &&
      user.clinicalRole &&
      user.clinicalRole.displayName !== null &&
      user.clinicalRole.displayName.toLowerCase()

    return clinicalRole && clinicalRole.includes(sanitizedSearch)
  })
  searchText = searchText.toLowerCase()
  const notLetter = /[,./;'"]\s?/g

  const search = searchText ? searchText.replace(notLetter, '') : searchText

  const fullNameSearch = users.filter((user) => {
    const createFullName = user && user.lastName + ',' + user.firstName
    const fullName = createFullName.toLowerCase().replace(notLetter, '')
    const names = createFullName.toLowerCase().split(notLetter)
    const partialNames = names.map((name) => name.substring(0, 3))
    return (
      fullName.includes(search) ||
      (partialNames && partialNames.join('').includes(search))
    )
  })

  const results = _.uniq([
    ...user34Search,
    ...nameSearch,
    ...clinicalRoleSearch,
    ...fullNameSearch,
  ])
  return results
}
