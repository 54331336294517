const ReadOnlyBanner = ({ isEDUnit }) => {
  let message
  if (isEDUnit) {
    message = "For ED Units, the 1N and LIP roles are 'read only' access."
  } else {
    message = 'This unit is currently in “read only” access'
  }

  return (
    <div
      className="readOnly neu-text--caption"
      style={{
        backgroundColor: '#EFF8FD',
        textAlign: 'center',
        padding: '10px',
        boxShadow: '1px 2px 5px 1px !important',
      }}
    >
      {message}
    </div>
  )
}

export default ReadOnlyBanner
