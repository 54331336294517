import formatStaffList from './formatStaffList'
import { assignmentCountSort } from './assignmentCountSort'

export default function filterStaffPool(
  { selectedRole, userGroups, staffList, preferredStaff, idsForAssignedStaff },
  selectedStaffMember,
  sortDirection,
  isDraftAssignment,
) {
  if (!selectedRole) {
    console.warn('No selectedRole id found.')
    return []
  }
  if (!staffList || staffList.length === 0) {
    console.warn('No staff list.')
  }
  if (userGroups == null) {
    console.warn('User groups is undefined or null.')
  }
  let userRole = {}
  const staffPoolFiltered = []
  if (userGroups?.length > 0) {
    userGroups.forEach((userGroup) => {
      if (userGroup.id === selectedRole) {
        userRole = userGroup
      }
    })

    userRole.staffPoolClinicalRolesFilter &&
      userRole.staffPoolClinicalRolesFilter.forEach((role) => {
        staffList &&
          staffList.forEach((staffMember) => {
            if (
              staffMember.clinicalRole &&
              staffMember.clinicalRole.systemName === role
            ) {
              staffPoolFiltered.push(staffMember)
            }
          })
      })
  }

  // let fsStaffIdsList =
  //   userRole.roleGroup === 'Nursing' && preferredStaff.length > 0
  //     ? preferredStaff
  //     : allPreferredStaff

  const formattedStaffList =
    preferredStaff &&
    staffPoolFiltered &&
    formatStaffList(
      preferredStaff,
      staffPoolFiltered,
      selectedStaffMember,
      idsForAssignedStaff,
      isDraftAssignment,
    )

  return assignmentCountSort(
    formattedStaffList,
    sortDirection,
    userRole.roleGroup === 'Nursing',
    isDraftAssignment,
  )
}
