const initialState = {
  assignmentAudit: [],
  fetchingReport: false,
  assignmentAuditError: null,
  snapshot: [],
  snapshotError: null,
  missingAssignments: [],
  missingAssignmentError: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'reports/FETCH_REPORT':
      return {
        ...state,
        fetchingReport: true,
      }
    case 'reports/FETCH_ASSIGNMENT_AUDIT_RECEIVED':
      return {
        ...state,
        assignmentAudit: action.payload,
        fetchingReport: false,
      }
    case 'reports/FETCH_ASSIGNMENT_AUDIT_ERROR':
      return { ...state, error: action.payload, fetchingReport: false }
    case 'reports/CLEAR_REPORT_DATA':
      return {
        ...state,
        assignmentAudit: [],
        snapshot: [],
        missingAssignments: [],
      }
    case 'reports/FETCH_SNAPSHOT_RECEIVED':
      return { ...state, snapshot: action.payload, fetchingReport: false }
    case 'reports/FETCH_SNAPSHOT_ERROR':
      return { ...state, snapshotError: action.payload, fetchingReport: false }
    case 'reports/FETCH_MISSING_ASSIGNMENTS_RECEIVED':
      return {
        ...state,
        missingAssignments: action.payload,
        fetchingReport: false,
      }
    case 'reports/FETCH_MISSING_ASSIGNMENTS_ERROR':
      return {
        ...state,
        missingAssignmentError: action.payload,
        fetchingReport: false,
      }

    default:
      return state
  }
}
