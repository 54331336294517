/**
 * External Imports
 * */
import { useCallback, useState, useEffect } from 'react'
import { debounce } from 'lodash'
import _ from 'lodash'
import { useAnalyticsApi } from '@shared-web-analytics/react/dist'
import { useSelector } from 'react-redux'
import Events from '../../analytics/events'
/**
 * Internal Imports
 * */
import StaffTable from './staffTable'
import Flex from '../reusableComponents/styleLibrary'

import filterStaffPool from '../../utils/filterStaffPool'
import { assignmentCountSort } from '../../utils/assignmentCountSort'
/**
 * Styling Imports
 * */
import { SortContainer, SortOptions } from './staff.styles'

const StaffContainer = ({
  staffPool,
  selectedStaffMember,
  selectedUserGroupId,
  userGroups,
  preferredStaff,
  idsForAssignedStaff,
  isDraftAssignment,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [searching, setSearching] = useState(false)
  const [staffSearchResults, setStaffSearchResults] = useState([])
  const [inputSelected, setInputSelected] = useState(false)
  const [showSort, setShowSort] = useState(false)
  const [sortDirection, setSortDirection] = useState('')
  const [filteredStaffPool, setFilteredStaffPool] = useState([])

  const { logTrackingEvent } = useAnalyticsApi()
  const orgId = useSelector((state) => state.user.userSettings.facilityCoid)

  const handleClick = useCallback(
    (e) => {
      if (showSort && e.target.id !== 'shadow') {
        setShowSort(false)
      }
      if (e.target.id !== 'Staff-Search-Box' && inputSelected) {
        setInputSelected(false)
      }
    },
    [showSort, inputSelected],
  )

  useEffect(() => {
    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [handleClick])

  const handleStaffList = useCallback(
    (_sortDirection) => {
      setFilteredStaffPool(
        filterStaffPool(
          {
            staffList: staffPool,
            selectedRole: selectedUserGroupId,
            userGroups: userGroups.userGroups,
            preferredStaff: preferredStaff.preferredStaff,
            idsForAssignedStaff,
          },
          selectedStaffMember,
          _sortDirection,
          isDraftAssignment,
        ),
      )
      setSortDirection(_sortDirection)
    },
    [
      idsForAssignedStaff,
      isDraftAssignment,
      preferredStaff.preferredStaff,
      selectedStaffMember,
      selectedUserGroupId,
      staffPool,
      userGroups.userGroups,
    ],
  )

  useEffect(() => {
    handleStaffList('desc')
    setStaffSearchResults(
      filterResults(inputValue, staffPool, selectedStaffMember),
    )
  }, [inputValue, staffPool, selectedStaffMember, handleStaffList])

  useEffect(() => {
    return setFilteredStaffPool(
      filterStaffPool(
        {
          staffList: staffPool,
          selectedRole: selectedUserGroupId,
          userGroups: userGroups.userGroups,
          preferredStaff: preferredStaff.preferredStaff,
          idsForAssignedStaff,
        },
        selectedStaffMember,
        sortDirection,
        isDraftAssignment,
      ),
    )
  }, [
    idsForAssignedStaff,
    isDraftAssignment,
    preferredStaff.preferredStaff,
    selectedStaffMember,
    selectedUserGroupId,
    sortDirection,
    staffPool,
    userGroups.userGroups,
  ])

  // searchText as inputValue, list as staffPool,
  const filterResults = (searchText, list, selectedStaffMember) => {
    searchText = searchText.toLowerCase()
    const notLetter = /[,./;'"]\s?/g

    const search = searchText ? searchText.replace(notLetter, '') : searchText
    const sanitizedSearchText = search.includes(' ')
      ? search.replace(' ', '')
      : search

    const user34Search = list.filter((staff) => {
      const hca34 = staff.hca34 && staff.hca34.toLowerCase().replace(/\s+/g, '')
      return hca34 && hca34.includes(search)
    })

    const fullNameSearchImp = (searchText) => {
      const sanitizedSearchText = searchText
        .toLowerCase()
        .replace(/,\s*/g, ' ')
        .replace(/\s+/g, ' ')
        .trim()

      return list.filter((staff) => {
        if (!staff) return false

        const firstName = staff.firstName?.toLowerCase().trim() || ''
        const lastName = staff.lastName?.toLowerCase().trim() || ''

        const fullName = `${firstName} ${lastName}`.replace(/\s+/g, ' ').trim()
        const reverseFullName = `${lastName} ${firstName}`
          .replace(/\s+/g, ' ')
          .trim()

        return (
          fullName.includes(sanitizedSearchText) ||
          reverseFullName.includes(sanitizedSearchText)
        )
      })
    }

    const fullNameSearchResults = fullNameSearchImp(searchText)

    if (selectedStaffMember.length > 0) {
      const results = _.uniq([...user34Search, ...fullNameSearchResults])
      return results
    } else {
      const results = _.uniq([...user34Search, ...fullNameSearchResults])
      return results
    }
  }
  const handleSearch = debounce((value) => {
    const searchText = value.trim()
    if (!searchText) {
      setInputValue('')
      setSearching(false)
      setStaffSearchResults([])
    } else {
      setInputValue(searchText)
      setSearching(true)
      setStaffSearchResults(
        filterResults(searchText, staffPool, selectedStaffMember),
      )
    }
  }, 750)

  const border = inputSelected
    ? '1px solid rgba(85,160,200, .75)'
    : '1px solid rgba(34,36,38,.15)'

  const isNursing =
    userGroups.userGroups.filter(
      (group) =>
        group.id === selectedUserGroupId && group.roleGroup === 'Nursing',
    ).length > 0
  const sortSearchSelectedStaffAtTop =
    assignmentCountSort(
      _.orderBy(staffSearchResults, (n) => n.lastName),
      sortDirection,
      isNursing,
      isDraftAssignment,
    ) || []

  /**
   * Tracking clicks
   */
  const trackingClicks = () => {
    const ctaLastPath = localStorage.getItem('ctaLastPath')
    if (!isDraftAssignment && ctaLastPath.includes('locationassignments')) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[0].Name,
          eventName:
            Events.Locations.Screen[0].Events.StaffPoolSearchFieldClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (
      isDraftAssignment &&
      ctaLastPath.includes('locationassignments')
    ) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[1].Name,
          eventName:
            Events.Locations.Screen[1].Events.StaffPoolSearchFieldClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (ctaLastPath.includes('patientassignments')) {
      logTrackingEvent([
        {
          module: Events.Patients.Module,
          screen: Events.Patients.Screen.Name,
          eventName: Events.Patients.Screen.Events.StaffPoolSearchFieldClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    }
  }

  return (
    <div style={{ height: '100%' }}>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            padding: '8px 14px',
            justifyContent: 'space-between',
            background: '#fff',
            borderRadius: '.28571429rem',
            border,
            width: isDraftAssignment ? '100%' : '80%',
          }}
        >
          <div
            style={{ display: 'flex', width: '100%' }}
            onClick={() => {
              setInputSelected(true)
              trackingClicks()
            }}
          >
            <i className="search icon" style={{ color: '#9a9a9a' }} />
            <input
              id="Staff-Search-Box"
              className=""
              type="text"
              style={{
                width: '100%',
                border: 'none',
                outline: 'none',
                marginLeft: '14px',
              }}
              placeholder="Search staff"
              onChange={(event) => handleSearch(event.target.value)}
            />
          </div>

          {inputSelected ? (
            <div
              className=""
              onClick={() => {
                handleSearch('')
                const input = document.getElementById('Staff-Search-Box')
                input.value = ''
              }}
            >
              {inputValue.length > 0 && (
                <i
                  className="cancel icon"
                  style={{ cursor: 'pointer', color: '#9a9a9a' }}
                />
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        {!isDraftAssignment ? (
          <div
            id="Sort-Button"
            style={{
              display: 'flex',
              color: '#00558C',
              fontSize: '12px',
              paddingTop: '5px',
              paddingLeft: '10px',
              cursor: 'pointer',
              height: 'auto',
              width: 'max-content',
            }}
            onClick={() => {
              setShowSort(!showSort)
            }}
          >
            <i className="material-icons">sort</i>
            <div
              style={{
                paddingTop: '2px',
                fontWeight: 'bolder',
                fontFamily: 'HCA-Mark-Bold, Arial, sans-serif',
              }}
              onClick={(e) => {
                setShowSort(!showSort)
              }}
            >
              Sort
            </div>
            <div
              id="shadow"
              style={{
                width: '30%',
                height: '50%',
                position: 'absolute',
                background: 'transparent',
                cursor: 'pointer',
                top: 0,
              }}
              onClick={(e) => setShowSort(!showSort)}
            />
          </div>
        ) : (
          <></>
        )}
        {showSort ? (
          <SortContainer id="Sort-Container">
            <div>
              <SortOptions>
                <Flex
                  onClick={(e) => {
                    handleStaffList('desc')
                  }}
                  style={{
                    padding: '10px 15px 15px 0',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ padding: '5px 5px 0px 15px' }}>
                    Count Descending
                  </div>
                  {sortDirection === 'desc' && (
                    <i className="material-icons" style={{ color: '#00558C' }}>
                      check
                    </i>
                  )}
                </Flex>
                <Flex
                  onClick={(e) => {
                    handleStaffList('asc')
                  }}
                  style={{
                    padding: '10px 15px 15px 0',
                    justifyContent: 'space-between',
                    borderTop: '1px solid',
                  }}
                >
                  <div style={{ padding: '5px 5px 0px 15px' }}>
                    Count Ascending
                  </div>
                  {sortDirection === 'asc' && (
                    <i className="material-icons" style={{ color: '#00558C' }}>
                      check
                    </i>
                  )}
                </Flex>
              </SortOptions>
            </div>
          </SortContainer>
        ) : (
          <></>
        )}
      </div>
      {staffPool ? (
        <StaffTable
          list={
            searching
              ? _.uniqBy(
                  [...sortSearchSelectedStaffAtTop, ...staffSearchResults],
                  'hca34',
                )
              : filteredStaffPool
          }
          headerHeight={0}
          isDraftAssignment={isDraftAssignment}
        ></StaffTable>
      ) : selectedStaffMember[0] ? (
        <StaffTable
          list={selectedStaffMember}
          headerHeight={0}
          isDraftAssignment={isDraftAssignment}
        />
      ) : (
        <div
          style={{
            paddingTop: '40px',
            marginLeft: '10px',
            fontSize: '16px',
            fontWeight: '300',
          }}
        >
          No preferred staff found.
        </div>
      )}
    </div>
  )
}

export default StaffContainer
