import Background from '../errorPages/Background.png'
import CareTeamLogo from '../errorPages/CareTeamLogo.svg'

const GenericError = () => {
  return (
    <>
      <img
        alt="HCA Background"
        style={{
          minHeight: '100%',
          minWidth: '100%',
        }}
        src={Background}
      />

      <div style={{ top: '30%', left: '40%', position: 'absolute' }}>
        <div style={{ alignItems: 'center' }}>
          <img
            alt="Care Team Logo"
            style={{ paddingLeft: '100px', height: '18.95px' }}
            src={CareTeamLogo}
          />
          <br />
          <br />
          <h1
            style={{
              paddingLeft: '20px',
              color: 'white',
              fontSize: '120px',
            }}
          >
            Oops
          </h1>
          <br />
          <br />
          <h3
            style={{
              color: 'white',
              fontSize: '34px',
              fontWeight: 'bold',
            }}
          >
            Something went wrong
          </h3>
          <p
            style={{
              paddingLeft: '60px',
              color: 'white',
              fontSize: '16px',
            }}
          >
            Try restarting the application.
          </p>

          <div style={{ paddingLeft: '100px' }}>
            <a href="/">
              <button
                style={{
                  height: '40px',
                  width: '126px',
                  borderRadius: '5px',
                  backgroundColor: ' #00558C',
                  color: 'white',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                {' '}
                Go Home
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default GenericError
