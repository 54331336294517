/**
 * External Imports
 * */
import { useState } from 'react'
/**
 * Internal Imports
 * */
import { HourSelector, MinuteSelector } from '../timeSelector/TimeSelector'
/**
 * Styling Imports
 * */
import './input.scss'

const Input = (props) => {
  const [selected, setSelected] = useState(false)
  let calMinLength
  if (props.type === 'hour' || props.type === 'minute') {
    calMinLength = '2'
  } else if (props.type === 'time') {
    calMinLength = '4'
  } else if (props.type === 'text') {
    calMinLength = '0'
  }
  let calMaxLength
  if (props.type === 'hour' || props.type === 'minute') {
    calMaxLength = '2'
  } else if (props.type === 'time') {
    calMaxLength = '5'
  } else if (props.type === 'text') {
    calMaxLength = '80'
  }
  return (
    <div className="neu-input" style={{ position: 'relative' }}>
      {selected && props.type === 'hour' && (
        <HourSelector
          hourSelected={(e) => {
            props.handleInput(e)
            setSelected(false)
          }}
        />
      )}
      {selected && props.type === 'minute' && (
        <MinuteSelector
          minuteSelected={(e) => {
            props.handleInput(e)
            setSelected(false)
          }}
        />
      )}
      <input
        className="input_styled"
        minLength={calMinLength}
        maxLength={calMaxLength}
        type={props.type}
        style={{ ...props.style }}
        name="uniqueInputName"
        aria-disabled="false"
        readOnly={props.readOnly}
        value={props.value}
        placeholder={props.placeholder ? props.placeholder : ''}
        onInput={(e) => {
          e.persist()
          props.handleInput(e.target.value)
          if (props.type === 'time' || 'hour' || 'minute') {
            setSelected(false)
          }
        }}
        onChange={(e) => {
          e.persist()
          props.handleInput(e.target.value)
          if (props.type === 'time' || 'hour' || 'minute') {
            setSelected(false)
          }
        }}
        onMouseDown={(e) => {
          if (props.type === 'time' || 'hour' || 'minute') {
            e.persist()
            setSelected(!selected)
          }
        }}
      />
    </div>
  )
}

export default Input
