/**
 * External Imports
 * */
import { NeuButton, NeuIcon } from '@neutron/react'
/**
 * Internal Imports
 * */
import store from '../../../redux/store'
import { discardStagedSlots } from '../../../redux/actions/stagedStaffSlots'

import { publishFunction } from '../../../utils/publish'

/**
 * Styling Imports
 * */
import { FlatTabButton } from '../styleLibrary'
import {
  UnpublishedModal,
  ButtonsDiv,
  ConfirmText,
} from './unpublishedChanges.styles'

export const UnpublishedChanges = ({
  eventProperties: propEventProperties,
  showUnpublishedState,
  stagedStaffSlots,
  page,
  siteId,
  selectedUnitIds,
  hideModal,
  authToken,
  ui,
  allUnitsSelected,
}) => {
  const eventProperties = propEventProperties
  const showHideUnpublishedClassName = showUnpublishedState
    ? 'modal display-block'
    : 'modal display-none'
  return (
    <div className={showHideUnpublishedClassName}>
      <UnpublishedModal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '10px',
            paddingLeft: '10px',
          }}
        >
          <h6 style={{ fontFamily: 'HCA-Mark-Bold', marginBottom: '10px' }}>
            Unpublished Changes.
          </h6>
          <FlatTabButton onClick={() => hideModal()}>
            <NeuIcon
              large
              style={{
                color: '#898B8E',
                cursor: 'pointer',
              }}
            >
              cancel
            </NeuIcon>
          </FlatTabButton>
        </div>
        <div
          style={{
            display: 'flex',
            paddingLeft: '10px',
          }}
        >
          <ConfirmText>
            You have selections that have not been published. How would you like
            to proceed?
          </ConfirmText>
        </div>
        <ButtonsDiv>
          <NeuButton
            fill="flat-white"
            className="neu-button"
            color="red-70"
            style={{ fontFamily: 'HCA-Mark-Bold' }}
            onClick={() => {
              // amplitude
              //   .getInstance()
              //   .logEvent(
              //     'UNPUBLISHED_MODAL_DISCARD_BUTTON_CLICKED',
              //     eventProperties,
              //   )
              store.dispatch(discardStagedSlots())
              hideModal()
            }}
          >
            Discard
          </NeuButton>
          <NeuButton
            className="neu-button"
            style={{
              backgroundColor: '#00558C',
              color: 'white',
              fontFamily: 'HCA-Mark-Bold',
            }}
            onClick={() => {
              // amplitude
              //   .getInstance()
              //   .logEvent(
              //     'UNPUBLISHED_MODAL_PUBLISH_BUTTON_CLICKED',
              //     eventProperties,
              //   )
              publishFunction(
                stagedStaffSlots,
                page,
                siteId,
                selectedUnitIds,
                hideModal(),
                authToken,
                ui,
                allUnitsSelected,
              )
            }}
          >
            Publish
          </NeuButton>
        </ButtonsDiv>
      </UnpublishedModal>
    </div>
  )
}
