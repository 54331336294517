const initialState = {
  rooms: [],
  beds: [],
  fetchingLocations: false,
  bedsError: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'addLocations/FETCH_ALL_LOCATIONS':
      return { ...state, fetchingLocations: true }
    case 'addLocations/ALL_LOCATIONS_RECEIVED':
      const filteredRooms = []
      const filteredBeds = []
      action.payload.map((item) => {
        if (item.locationType.displayName === 'Room') {
          filteredRooms.push(item)
        } else if (item.locationType.displayName === 'Bed') {
          filteredBeds.push(item)
        }
      })
      return {
        ...state,
        beds: filteredBeds,
        rooms: filteredRooms,
        fetchingLocations: false,
      }
    case 'addLocations/ALL_LOCATIONS_ERROR':
      return { ...state, error: action.payload, fetchingLocations: false }
    default:
      return state
  }
}
