import { publishDraftAssignments } from '../../../redux/actions/draftAssignments'

// styles
import { NeuIcon, NeuButton } from '@neutron/react'
import { FlatTabButton } from '../styleLibrary'
import { PublishDraftModal, ConfirmText } from './publishDraftModal.styles'

const AssigningToAllUnits = ({
  showPublishModalState,
  dispatch,
  hidePublishModal,
  authToken,
  siteId,
  selectedUnitIds,
  ui,
  draftLocations,
  handlePublishState,
}) => {
  const showAssignToAllClass = showPublishModalState
    ? 'modal display-block'
    : 'modal display-none'

  return (
    <div className={showAssignToAllClass}>
      <PublishDraftModal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '10px',
            paddingLeft: '10px',
          }}
        >
          <h6 style={{ fontFamily: 'HCA-Mark-Bold', marginBottom: '10px' }}>
            Publishing Draft Assignment
          </h6>
          <FlatTabButton
            onClick={() => {
              dispatch({
                type: 'stagedStaffSlots/ASSIGNING',
                payload: false,
              })

              hidePublishModal()
            }}
          >
            <NeuIcon color="gray-50" large>
              cancel
            </NeuIcon>
          </FlatTabButton>
        </div>
        <div
          style={{
            display: 'flex',
            paddingLeft: '10px',
          }}
        >
          <ConfirmText>
            This will now replace all current assignments, please confirm.
          </ConfirmText>
        </div>

        <div
          className={'navItemText'}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '10px',
            paddingTop: '20px',
          }}
        >
          <NeuButton
            color="danger"
            fill="flat-white"
            onClick={() => {
              hidePublishModal()
            }}
          >
            Cancel
          </NeuButton>

          <NeuButton
            color="primary-80"
            fill="raised"
            onClick={() => {
              dispatch(
                publishDraftAssignments(
                  authToken,
                  siteId,
                  selectedUnitIds,
                  ui,
                  draftLocations,
                ),
              )
              handlePublishState()
              hidePublishModal(false)
              // amplitude
              //   .getInstance()
              //   .logEvent(
              //     'PRIMARY_PUBLISH_BUTTON_CLICKED',
              //     eventProperties,
              //   )
            }}
          >
            Publish
          </NeuButton>
        </div>
      </PublishDraftModal>
    </div>
  )
}

export default AssigningToAllUnits
