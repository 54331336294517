import px from './px'
import './MarkforHCA-Medium-normal'
import './MarkforHCA-Regular-normal'

export default function ({ doc, abbreviation, displayName, layout }) {
  const { xPos, yPos } = layout

  doc.addFont('MarkforHCA-Regular-normal.ttf', 'MarkforHCA-Regular', 'normal')
  doc.addFont('MarkforHCA-Medium-normal.ttf', 'MarkforHCA-Medium', 'normal')

  return doc
    .setFontSize(8)
    .setFillColor('#FFFFFF')
    .rect(xPos, yPos, px(125), px(25), 'F')
    .setFillColor('#BCBCBC')
    .roundedRect(xPos, yPos, px(120), px(25), px(12.5), px(12.5), 'F')
    .setFillColor('#54575A')
    .roundedRect(
      xPos + px(2.5),
      yPos + px(2.5),
      px(getSize(abbreviation)) + px(18),
      px(20),
      px(10),
      px(10),
      'F',
    )
    .setFont('MarkforHCA-Medium', 'normal')
    .setTextColor('#FFFFFF')
    .text(abbreviation, xPos + px(7.5), yPos + px(16))
    .setFont('MarkforHCA-Regular', 'normal')
    .setTextColor('#54575A')
    .text(
      displayName,
      xPos + (px(120) - doc.getStringUnitWidth(displayName)) / 2,
      yPos + px(16),
    )
    .setTextColor('#000000')
}

function getSize(abbreviation) {
  switch (abbreviation.length) {
    case 0:
      return 0
    case 1:
      return 6
    case 2:
      return 9
    case 3:
      return 15
    case 4:
      return 23
    default:
      return 15
  }
}
