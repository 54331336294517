import Background from '../errorPages/Background.png'
import CareTeamLogo from '../errorPages/CareTeamLogo.svg'

const NotFound = () => {
  return (
    <>
      <img
        style={{
          backgroundColor: '#E05929',
          minHeight: '100%',
          minWidth: '100%',
        }}
        src={Background}
        alt="HCA Background"
      />
      <div style={{ top: '30%', left: '40%', position: 'absolute' }}>
        <div style={{ alignItems: 'center' }}>
          <img
            style={{ paddingLeft: '100px', height: '18.95px' }}
            src={CareTeamLogo}
            alt="Care Team Logo"
          />
          <br />
          <br />
          <h1
            style={{
              paddingLeft: '40px',
              color: 'white',
              fontSize: '140px',
            }}
          >
            404
          </h1>
          <br />
          <br />
          <h3
            style={{
              paddingLeft: '20px',
              color: 'white',
              fontSize: '40px',
              fontWeight: 'bold',
            }}
          >
            Page Not Found
          </h3>
          <p
            style={{
              color: 'white',
              paddingLeft: '90px',
              fontSize: '16px',
            }}
          >
            It looks like you're lost.
          </p>
          <div style={{ paddingLeft: '100px' }}>
            <a href="/">
              <button
                style={{
                  height: '40px',
                  width: '126px',
                  borderRadius: '5px',
                  backgroundColor: ' #00558C',
                  color: 'white',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                {' '}
                Go Home
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
