/**
 * Internal Imports
 * */
import Reports from '../reports/reports'

const ReportsLinks = [
  {
    link: '/reports/snapshot',
    navItemText: 'Snapshot',
    //Current auth roles are user and admin
    allowedRoles: ['ViewReports'],
    component: (props) => {
      const customProps = props
      customProps.selPage = 'reports'
      customProps.selRoute = 'snapshot'
      return () => <Reports {...customProps} />
    },
    navBarItem: false,
  },
  {
    link: '/reports/missingassignments',
    navItemText: 'Missing Assignments',
    //Current auth roles are user and admin
    allowedRoles: ['ViewReports'],
    component: (props) => {
      const customProps = props
      customProps.selPage = 'reports'
      customProps.selRoute = 'missingassignments'
      return () => <Reports {...customProps} />
    },
    navBarItem: false,
  },
  {
    link: '/reports/assignmentaudit',
    navItemText: 'Assignment Audit',
    //Current auth roles are user and admin
    allowedRoles: ['ViewReports'],
    component: (props) => {
      const customProps = props
      customProps.selPage = 'reports'
      customProps.selRoute = 'assignmentaudit'
      return () => <Reports {...customProps} />
    },
    navBarItem: false,
  },
]

export default ReportsLinks
