/**
 * External Imports
 * */
import { connect } from 'react-redux'
import moment from 'moment'
import { NeuIcon } from '@neutron/react'
/**
 * Internal Imports
 * */
import store from '../../../redux/store.js'

import pdf from '../../../utils/pdf/pdf.js'
/**
 * Styling Imports
 * */
import { PDFPrint } from './print.styles.js'

const siteDisplay = (siteId, sites) =>
  sites.filter((site) => siteId === site.siteId)[0].displayName

const PrintPDF = ({ staffSlots, list: propsList, page }) => {
  let state = store.getState()

  const download = () => {
    const upState = store.getState()

    const headers = [
      { label: 'Bed', key: 'displayName', multiplier: 1 },
      { label: 'Patient Name', key: 'name', multiplier: 1 },
      { label: 'Isolation Status', key: 'isolationStatus', multiplier: 1 },
    ]

    const staffSlotHeaders = staffSlots.map((staffSlot) => {
      const removePeriodsFromSystemName = staffSlot.systemName
        .split('.')
        .join('')
      const addStaffSlot = {
        label: staffSlot.abbreviation,
        key: removePeriodsFromSystemName,
        systemName: staffSlot.systemName || '',
        multiplier: 1,
      }
      return addStaffSlot
    })

    const finalHeaders = headers.concat(staffSlotHeaders)

    finalHeaders.push({ label: 'Notes', key: 'notes', multiplier: 1 })

    const rawData = []

    const formatData = () => {
      const arr = []
      const list = propsList

      // US 9828 - Include Unit
      // Sorts provided list array by the location.unit of each object.
      list.forEach((item) => {
        const unit = item.location.unit
        // Creates new object
        if (arr.find((x) => x.unit === unit) === undefined) {
          let unitObj = {}
          unitObj.unit = unit
          unitObj.unitData = []
          unitObj.unitData.push(item)
          arr.push(unitObj)
          // If unit object already exists, push item to unitData nested array
        } else {
          const unitObj = arr.find((x) => x.unit === unit)
          unitObj.unitData.push(item)
        }
      })

      return arr
    }

    const formattedData = formatData()

    // Format each object in the unit nested
    // Reformats each object to match the table headers
    formattedData.forEach((obj) => {
      let unitObj = {}
      unitObj.unit = obj.unit
      unitObj.unitData = []
      obj.unitData.forEach((row) => {
        let isolationStatus = row.patient.isolationStatus?.toLocaleString()
        let addRow = {
          displayName: ' ' + row.location.bed,
          name: row.patient.name || '',
          isolationStatus: isolationStatus || '',
        }

        unitObj.unitData.push(addRow)
        staffSlotHeaders.forEach((headerItem) => {
          const slotHeaderItem = headerItem.systemName

          const userName =
            (row[slotHeaderItem] &&
              row[slotHeaderItem][
                page === 'locations'
                  ? 'assignedToLocation'
                  : 'assignedToPatient'
              ].user) ||
            ''
          addRow[headerItem.key] = userName
        })
      })
      rawData.push(unitObj)
    })

    // Reformats each object in the nested unitData array to an array of the object values
    rawData.forEach((obj) => {
      const dataArr = []
      obj.unitData.forEach((row) => {
        const list = []
        // for every key in header
        finalHeaders.forEach((header) => {
          if (row[header.key]) {
            list.push(row[header.key])
          } else {
            list.push('')
          }
        })
        dataArr.push(list)
      })
      obj.unitData = dataArr
    })

    const userGroup = upState.userGroups.userGroups.filter(
      (groupId) => groupId.id === upState.userGroups.selectedUserGroupId,
    )

    const template = {
      headers: finalHeaders,
      letterhead: null, // image/logo
      heading: 'Care Team Assignments', // title of report
      // TO REMOVE
      subHeading: `${siteDisplay(
        state.sites.selectedSiteId,
        state.sites.sites,
      )}  |  ${userGroup[0].roleGroup}  |  `, // will be displayed underneath title
      reportLength: rawData.length, // length of data
      reportType: 'assignments', // will be displayed as, i.e: 85 stoplights or 128 nurses assigned to beds, etc.
      date: moment().format('MM/DD/YYYY'), // if no date given, will generate with a date of current day in MM/DD/YYYY format
    }

    const config = {
      template,
      breadcrumb: template.subHeading || '',
      fileName: `CareTeamAssignments_${moment().format('YYYYMMDDHHmmss')}`,
    }

    pdf(rawData, config)
  }

  return (
    <PDFPrint onClick={download} color="blue-80" fill="flat-white">
      <NeuIcon slot="start" large>
        local_printshop
      </NeuIcon>
      PDF
    </PDFPrint>
  )
}

export default connect((state) => state)(PrintPDF)
