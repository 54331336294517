const initialState = {
  allPreferredStaff: [],
  preferredStaff: [],
  error: null,
  allFSError: null,
  fetching: false,
  allFSFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'assignments/FETCH_PREFERRED_STAFF_POOL_ERROR':
      return { ...state, error: action.payload }
    case 'assignments/FETCH_ALL_PREFERRED_STAFF_POOL_ERROR':
      return { ...state, allFSError: action.payload }
    case 'assignments/SPECIFIC_PREFERRED_STAFF':
      return {
        ...state,
        preferredStaff: action.payload,
      }
    case 'assignments/ALL_PREFERRED_STAFF':
      return {
        ...state,
        allPreferredStaff: action.payload,
      }
    case 'assignments/FETCH_PREFERRED_STAFF_POOL_FETCHING':
      return {
        ...state,
        fetching: action.payload,
      }
    case 'assignments/FETCH_ALL_PREFERRED_STAFF_POOL_FETCHING':
      return {
        ...state,
        allFSFetching: action.payload,
      }
    case 'assignments/CLEAR_PREFERRED_STAFF_POOL':
      return {
        ...state,
        allPreferredStaff: [],
        preferredStaff: [],
      }

    default:
      return state
  }
}
