import styled from 'styled-components'
import { NeuButton } from '@neutron/react'

//PrintCSV

export const CSVPrint = styled(NeuButton)`
  font-size: 0.9em !important;
  margin-top: 0px !important;
  display: flex;
`
//PrintPDF
export const PDFPrint = styled(NeuButton)`
  font-size: 0.9em !important;
  margin-top: 0px !important;
  display: flex;
  color: #005589;
  margin-top: 0;
  margin-right: 2%;
`
