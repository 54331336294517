import axios from 'axios'
import store from '../redux/store'

const axiosRequest = (req) =>
  axios({
    ...req,
    headers: {
      ...req.headers,
      facilityCoid:
        store
          .getState()
          .sites?.sites?.find((site) => site.siteId === req.headers.siteId)
          ?.facilityCoid || '',
    },
  })

export default axiosRequest
