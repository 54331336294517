export default function (reportSource) {
  const common = {}
  switch (reportSource) {
    case 'snapshot':
      return Object.assign({}, common, {
        assignedPosition: '',
        assignedStaffMember: '',
        lastUpdated: '',
      })
    case 'missingAssignments':
      return Object.assign({}, common, {
        assignmentFilled: '',
        missingDate: '',
        missing: '',
      })
    case 'assignmentAudit':
      return Object.assign({}, common, {
        action: '',
        assignment34: '',
        time: '',
        assignmentType: '',
        staffMember: '',
        madeBy: '',
        user34: '',
        position: '',
      })
    default:
      return Object.assign({}, common, {})
  }
}
