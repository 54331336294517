import styled from 'styled-components'

export const PublishDraftModal = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  border: solid 1px;
  left: 40%;
  top: 35%;
  height: 200px;
  border: none;
  border-radius: 5px;
  width: 420px;
  z-index: 2;
  padding: 10px;
  box-shadow: inset 0 10px 8px -10px rgba(0, 0, 0, 0.3);
`

export const ConfirmText = styled.p`
  font-size: 16px;
  line-height: 30px,
  letter-spacing: 0.2p,
  padding-top:10px
`
