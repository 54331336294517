const initialState = {
  error: null,
  fetching: false,
  allFSfetching: false,
  draftAssignments: [],
  draftIsLoading: false,
  draftLocations: [],
  preferredStaff: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'draftAssignments/FETCH_DRAFT_ASSIGNMENTS':
      return {
        ...state,
        fetching: true,
        draftIsLoading: true,
      }
    case 'draftAssignments/FETCH_DRAFT_ASSIGNMENTS_RECIEVED':
      return {
        ...state,
        fetching: false,
        draftAssignments: action.payload,
        draftIsLoading: false,
      }
    case 'draftAssignments/FETCH_DRAFT_ASSIGNMENT_LOCATIONS_RECEIVED':
      return {
        ...state,
        draftLocations: action.payload,
        draftIsLoading: false,
      }
    case 'draftAssignments/FETCH_DRAFT_ASSIGNMENTS_ERROR':
      return {
        ...state,
        error: action.payload,
        fetching: false,
        draftIsLoading: false,
      }
    case 'draftAssignments/SAVE_DRAFT_ASSIGNMENTS':
      return {
        ...state,
        fetching: true,
        draftIsLoading: true,
      }
    case 'draftAssignments/SAVE_DRAFT_ASSIGNMENTS_RECEIVED':
      return {
        ...state,
        fetching: false,
        draftAssignments: action.payload,
      }
    case 'draftAssignments/SAVE_DRAFT_ASSIGNMENTS_ERROR':
      return {
        ...state,
        error: action.payload,
      }
    case 'draftAssignments/DISCARD_DRAFT_ASSIGNMENTS':
      return {
        ...state,
        fetching: true,
        draftIsLoading: true,
      }
    case 'draftAssignments/DISCARD_DRAFT_ASSIGNMENTS_SUCCESS':
      return {
        ...state,
        fetching: false,
        draftAssignments: [],
      }
    case 'draftAssignments/PUBLISH_DRAFT_ASSIGNMENTS':
      return {
        ...state,
        fetching: true,
        draftIsLoading: true,
      }
    case 'draftAssignments/PUBLISH_DRAFT_ASSIGNMENTS_RECEIVED':
      return {
        ...state,
        fetching: false,
        draftAssignments: action.payload,
        draftIsLoading: false,
      }
    case 'draftAssignments/FETCH_PREFERRED_DRAFT_STAFF_POOL_FETCHING':
      return {
        ...state,
        fetching: action.payload,
      }
    case 'draftAssignments/FETCH_PREFERRED_DRAFT_STAFF_POOL_ERROR':
      return { ...state, error: action.payload }

    case 'draftAssignments/SPECIFIC_PREFERRED_STAFF':
      return {
        ...state,
        preferredStaff: action.payload,
      }
    case 'draftAssignments/PUBLISH_DRAFT_ASSIGNMENTS_ERROR':
      return {
        ...state,
        fetching: false,
        error: action.payload,
      }

    default:
      return state
  }
}
