import axios from '../../utils/axiosWrapper'
import environment from '../../environment'
import {
  fetchLocationsWithMultipleUnits,
  fetchAllAssignments,
} from './locations'

import { fetchStaffPool, getStaffAssignmentMetadata } from './staffPool'

const serverUrl = environment._serverUrl

export const publish =
  (siteId, authToken, payload, selUnitId, ui, allUnitsSelected) =>
  (dispatch) => {
    const endpointForPublish = serverUrl + `Assignment/Bulk`
    const filterDuplicateAssignment = payload.filter(
      (assignment, index, self) =>
        self.findIndex(
          (i) =>
            i.AssignmentSlot.SystemName ===
              assignment.AssignmentSlot.SystemName &&
            i.EntityId === assignment.EntityId,
        ) === index,
    )
    dispatch({ type: 'stagedStaffSlots/PUBLISH', payload: true })

    axios({
      method: 'post',
      url: endpointForPublish,
      data: filterDuplicateAssignment,
      headers: {
        authorization: authToken,
        siteId,
        Pragma: 'no-cache',
      },
    })
      .then(function (response) {
        dispatch({
          type: 'stagedStaffSlots/PUBLISH_SUCCESS',
          payload: response.data,
        })
        dispatch({ type: 'stagedStaffSlots/PUBLISH', payload: false })

        // saving: false

        allUnitsSelected
          ? dispatch(fetchAllAssignments(authToken, siteId, ui))
          : dispatch(
              fetchLocationsWithMultipleUnits(authToken, siteId, selUnitId, ui),
            )

        dispatch(discardStagedSlots())
        dispatch(fetchStaffPool(authToken, siteId))
        dispatch(
          getStaffAssignmentMetadata({
            authorization: authToken,
            siteId,
          }),
        )
      })
      .catch(function (error) {
        console.warn(error.response)
        dispatch({
          type: 'stagedStaffSlots/PUBLISH_ERROR',
          payload: error.response,
        })
      })
  }

export const assignSlots =
  ({ data, assignmentType, selStaffMember }) =>
  (dispatch) => {
    const staffMember = selStaffMember || ''
    dispatch({
      type: 'stagedStaffSlots/ASSIGN_SLOTS',
      payload: { data, assignmentType, staffMember },
    })
    setTimeout(
      () => dispatch({ type: 'stagedStaffSlots/ASSIGNING', payload: false }),
      850,
    )
    dispatch({ type: 'assignments/CLEAR_ALL_SELECTED_STAFF' })
  }

export const discardStagedSlots = () => (dispatch) => {
  dispatch({
    type: 'stagedStaffSlots/DISCARD_SLOTS',
  })
  dispatch({
    type: 'stagedStaffSlots/EDITING_SLOTS',
    payload: false,
  })
}

export const unassignSlots =
  ({ data, assignmentType }) =>
  (dispatch) => {
    dispatch({
      type: 'stagedStaffSlots/ASSIGN_SLOTS',
      payload: { data, assignmentType },
    })
  }
export const editing = (data) => (dispatch) => {
  dispatch({
    type: 'stagedStaffSlots/EDITING_SLOTS',
    payload: data,
  })
}
