// styles
import { NeuLabel, NeuIcon } from '@neutron/react'
import { Snackbar } from './nursingAssignmentWarning.styles'

const NursingAssignmentWarning = () => {
  return (
    <div>
      <Snackbar className="align-items-center">
        <NeuLabel
          slot="title"
          color="gray-100"
          className="mt-5 d-flex align-self-center"
        >
          <NeuIcon className="mb-5 mr-2" large>
            info
          </NeuIcon>
          Direct care nurses should use Location Assignment tab.
        </NeuLabel>
      </Snackbar>
    </div>
  )
}
export default NursingAssignmentWarning
