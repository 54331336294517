import axios from '../../utils/axiosWrapper'
import { v4 as uuid } from 'uuid'
import environment from '../../environment'
import ErrorCheck from '../../utils/errorCheck'

const serverUrl = environment._serverUrl

export const getAssignmentAudit =
  ({
    siteId,
    time,
    type,
    unitId,
    position,
    authToken,
    utcStartDate,
    utcEndDate,
    utcStartTime,
    utcEndTime,
  }) =>
  (dispatch) => {
    let endpointForAuthUser = serverUrl + `Reporting/AssignmentAudit/${time}?`
    endpointForAuthUser += utcStartDate ? '&startDate=' + utcStartDate : ''
    endpointForAuthUser += utcEndDate ? '&endDate=' + utcEndDate : ''
    endpointForAuthUser +=
      type === 'Location' || type === 'Patient' ? '&type=' + type : ''
    endpointForAuthUser += utcStartTime ? '&startTime=' + utcStartTime : ''
    endpointForAuthUser += utcEndTime ? '&endTime=' + utcEndTime : ''
    unitId.length > 0 &&
      unitId.forEach((u, index) => {
        endpointForAuthUser += `&unitIds=${u}`
      })

    position.length > 0 &&
      position.forEach((n, index) => {
        endpointForAuthUser += `&positions=${n}`
      })

    dispatch({
      type: 'reports/FETCH_REPORT',
    })
    axios({
      method: 'get',
      url: endpointForAuthUser,
      headers: {
        Authorization: authToken,
        siteId,
        Pragma: 'no-cache',
      },
    })
      .then(function (response) {
        dispatch({
          type: 'reports/FETCH_ASSIGNMENT_AUDIT_RECEIVED',
          payload: response.data,
        })
      })
      .catch(function (error) {
        ErrorCheck(error)
        if (error.response.status === 404) {
          dispatch({ type: 'reports/CLEAR_REPORT_DATA' })
        } else if (error.response.status === 408) {
          dispatch({
            type: 'ui/FLASH_MESSAGE',
            payload: {
              messages: [
                'Your report is taking too long to run.  Please filter your report criteria to limit the amount of data returned.',
              ],
              type: 'failure',
              uuid: uuid(),
            },
          })
        }
        dispatch({
          type: 'reports/FETCH_ASSIGNMENT_AUDIT_ERROR',
          payload: error.response,
        })
      })
  }

export const getSnapshot =
  ({ date, time, position, unit, authToken, siteId, isolationStatus }) =>
  (dispatch) => {
    let endpointForAuthUser =
      serverUrl + `Reporting/CareTeamSnapshot/${date}/${time}?`

    unit.length > 0 &&
      unit.forEach((u, index) => {
        endpointForAuthUser += index === 0 ? `unitIds=${u}` : `&unitIds=${u}`
      })

    position.length > 0 &&
      position.forEach((n, index) => {
        endpointForAuthUser += `&positions=${n}`
      })

    dispatch({
      type: 'reports/FETCH_REPORT',
    })
    axios({
      method: 'get',
      url: endpointForAuthUser,
      headers: {
        Authorization: authToken,
        siteId,
        Pragma: 'no-cache',
      },
    })
      .then(function (response) {
        dispatch({
          type: 'reports/FETCH_SNAPSHOT_RECEIVED',
          payload: response.data,
        })
      })
      .catch(function (error) {
        ErrorCheck(error)
        if (error.response && error.response.status === 404) {
          dispatch({ type: 'reports/CLEAR_REPORT_DATA' })
        } else if (error.response && error.response.status === 408) {
          dispatch({
            type: 'ui/FLASH_MESSAGE',
            payload: {
              messages: [
                'Your report is taking too long to run.  Please filter your report criteria to limit the amount of data returned.',
              ],
              type: 'failure',
              uuid: uuid(),
            },
          })
        }
        dispatch({
          type: 'reports/FETCH_SNAPSHOT_ERROR',
          payload: error.response,
        })
      })
  }

export const getMissingAssignments =
  ({
    timeIncrementInHours,
    startDate,
    startTime,
    endDate,
    endTime,
    position,
    unit,
    authToken,
    siteId,
  }) =>
  (dispatch) => {
    let endpointForAuthUser =
      serverUrl + `Reporting/MissingAssignments/${timeIncrementInHours}?`
    unit.length > 0 &&
      unit.forEach((unit) => (endpointForAuthUser += `&unitIds=${unit}`))
    //Currently sending only 1N, 2N, CRN to backend instead of systemName like the other endpoints

    position.length > 0 &&
      position.forEach((n) => (endpointForAuthUser += `&positions=${n}`))

    endpointForAuthUser += startTime ? `&startTime=${startTime}` : ''
    endpointForAuthUser += startDate ? `&startDate=${startDate}` : ''
    endpointForAuthUser += endTime ? `&endTime=${endTime}` : ''
    endpointForAuthUser += endDate ? `&endDate=${endDate}` : ''

    dispatch({
      type: 'reports/FETCH_REPORT',
    })
    axios({
      method: 'get',
      url: endpointForAuthUser,
      headers: {
        Authorization: authToken,
        siteId,
        Pragma: 'no-cache',
      },
    })
      .then(function (response) {
        dispatch({
          type: 'reports/FETCH_MISSING_ASSIGNMENTS_RECEIVED',
          payload: response.data,
        })
      })
      .catch(function (error) {
        ErrorCheck(error)
        if (error.response && error.response.status === 404) {
          dispatch({ type: 'reports/CLEAR_REPORT_DATA' })
        } else if (error.response.status === 408) {
          dispatch({
            type: 'ui/FLASH_MESSAGE',
            payload: {
              messages: [
                'Your report is taking too long to run.  Please filter your report criteria to limit the amount of data returned.',
              ],
              type: 'failure',
              uuid: uuid(),
            },
          })
        }
        dispatch({
          type: 'reports/FETCH_MISSING_ASSIGNMENTS_ERROR',
          payload: error.response,
        })
      })
  }

export const clearReportData = () => (dispatch) => {
  dispatch({
    type: 'reports/CLEAR_REPORT_DATA',
  })
}
