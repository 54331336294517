const Health = () => {
  return (
    <div
      className="text-2xl"
      style={{
        height: 400,
        width: '100%',
        marginTop: 150,
      }}
    >
      <div style={{ margin: '0 auto', width: 600 }}>
        <img
          alt="Care Team Logo"
          style={{
            height: '87px',
            display: 'inline',
            marginRight: '5px',
            marginBottom: '55px',
          }}
          src={require('../reusableComponents/CTA.png')}
        />
        <div
          style={{
            fontSize: '87px',
            display: 'inline-block',
            marginTop: '15px',
          }}
        >
          Care Team
        </div>
        <div style={{ width: 180, margin: '0 auto' }}>Is super healthy!</div>
      </div>
    </div>
  )
}
export default Health
