import styled from 'styled-components'

// AssigningToAllUnits

export const AssignToAllModal = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 40%;
  top: 35%;
  height: 195px;
  border-radius: 5px;
  width: 410px;
  z-index: 2;
  padding: 10px;
  box-shadow: inset 0 10px 8px -10px rgba(0, 0, 0, 0.3);
`
// AssignmentModal

export const ModalContainer = styled.div`
  :last-child {
    border-bottom: 1px solid lightgrey;
  }
`

export const CloseButton = styled.div`
  cursor: pointer;
`

export const Bubble = styled.div`
  display: inline-block;
  min-width: 25px;
  width: max-content;
  height: 25px;
  border-radius: 15px;
  line-height: 25px;
  background: #898b8e;
  color: #ffffff;
  text-align: center;
  margin-right: 5px;
  font-size: 0.75em;
  > span {
    padding: 0 5px;
  }
`

export const ModalRow = styled.div`
  padding: 10px;
  border-top: 1px solid lightgrey;
`

export const LocationSpan = styled.span`
  margin-left: 10px;
  font-family: 'HCA-MARK-BOLD';
  font-size: 1.1em;
  vertical-align: middle;
`
