import { createSelector } from 'reselect'
import { formatDropdownData } from '../../utils/formatDropdownData'

export default createSelector(
  (state, props) => props,
  (state) => state.units,
  (state) => state.userGroups,
  (state) => state.staffPool,
  (state) => state.selectedStaffSlots,
  (state) => state.stagedStaffSlots,
  (state) => state.sites,
  (state) => state.facilitySchedulerUsers,
  (state) => state.user,
  (
    props,
    unitsState,
    userGroupsState,
    staffPoolState,
    selLocationStaffSlotState,
    stagedStaffSlots,
    sitesState,
    facilitySchedulerUsersState,
    userState,
  ) => {
    const formattedUnits = formatDropdownData(unitsState.units)
    const formattedAllUnits = {
      key: 'all',
      value: '00000000-0000-0000-0000-000000000000',
      text: 'ALL',
    }

    const formattedUserGroups = userGroupsState.userGroups.map(
      (userGroup, index) => {
        //Commenting out until next release
        // const staffPool = staffPoolState && staffPoolState.staffPool
        // const staffPoolQty = filterStaffPool({
        //   staffList: staffPool || [],
        //   selectedRole: userGroup.id || '',
        //   userGroups: userGroupsState.userGroups || [],
        //   preferredStaff: facilitySchedulerUsersState.preferredStaff || [],
        // })

        return {
          key: index,
          value: userGroup.id,
          text: userGroup.roleGroup,
          staffpoolclinicalrolesfilter: userGroup.staffPoolClinicalRolesFilter,
        }
      },
    )
    const nursingGroupArray = []
    const additionalGroupArray = []
    const unsortedUserGroups = []

    formattedUserGroups.forEach((userGroup) => {
      if (userGroup.text === 'Nursing') {
        nursingGroupArray.push(userGroup)
      } else if (userGroup.text === 'Additional') {
        additionalGroupArray.push(userGroup)
      } else {
        unsortedUserGroups.push(userGroup)
      }
    })
    const sortedRemainingUserGroups = unsortedUserGroups.sort(function (a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    })
    const orderedFormattedUserGroups = nursingGroupArray
      .concat(sortedRemainingUserGroups)
      .concat(additionalGroupArray)

    const selGroup = userGroupsState.userGroups.filter((group) => {
      return group.id === userState.userSettings.preferredRoleGroup
    })

    const selGroupName = (selGroup.length && selGroup[0].roleGroup) || 'Nursing'

    const selectedStaffMember = staffPoolState.selectedStaffMember.length
      ? staffPoolState.selectedStaffMember[0]
      : null

    const allStagedStaffSlots = stagedStaffSlots.stagedStaffSlots

    const stagedStaffSlotErrors = stagedStaffSlots.error

    let hasStagedStaffSlots = false
    const lengthOfStagedLocationObject =
      Object.entries(allStagedStaffSlots).length

    if (lengthOfStagedLocationObject !== 0) {
      hasStagedStaffSlots = true
    }

    const selectedSlots = selLocationStaffSlotState.selectedStaffSlots

    const noSelections = !Object.keys(selectedSlots).length

    let hasAssignedSelections = false
    let hasUnassignedSelections = false

    if (!noSelections) {
      const entries = Object.values(selectedSlots)

      entries.forEach((entry) => {
        if (entry.isAssigned) {
          hasAssignedSelections = true
        } else {
          hasUnassignedSelections = true
        }
      })
    }
    const selSiteId = sitesState.selectedSiteId

    const authToken = userState.token

    const selectedUnitIds = unitsState.selectedUnitIds
      ? unitsState.selectedUnitIds
      : []

    const result = {
      authToken,
      selectedGroup: selGroupName,
      formattedUnits: [formattedAllUnits, ...formattedUnits],
      formattedUserGroups: orderedFormattedUserGroups,
      userGroups: userGroupsState.userGroups,
      selectedStaffMember: selectedStaffMember,
      hasAssignedSelections: hasAssignedSelections,
      hasUnassignedSelections: hasUnassignedSelections,
      selectedSlots: selectedSlots,
      stagedStaffSlots: allStagedStaffSlots,
      hasStagedStaffSlots: hasStagedStaffSlots,
      siteId: selSiteId,
      selectedUnitIds: selectedUnitIds,
      user: userState,
      stagedStaffSlotErrors: stagedStaffSlotErrors,
      userSettings: userState.userSettings,
      staffPool: staffPoolState.staffPool,
      saving: stagedStaffSlots.saving,
      assigning: stagedStaffSlots.assigning,
      ...props,
    }
    return result
  },
)
