/**
 * External Imports
 * */
import { NeuButton, NeuIcon } from '@neutron/react'

const SearchBar = ({
  selectedSite,
  dataType,
  selectedUnit,
  selectedRoom,
  path,
  userPermissions,
  handleBackButton,
  searchText,
  handleSearch,
  dispatch,
  authToken,
  clinicalRoles,
  adminFacilityCoid,
}) => {
  let backToPageText
  if (selectedSite) {
    if (dataType === 'Units') backToPageText = 'Sites'
    if (!selectedUnit) {
      if (dataType === 'Rooms') backToPageText = 'Sites'
      if (!selectedRoom && dataType === 'Beds') backToPageText = 'Sites'
      if (selectedRoom && dataType === 'Beds') backToPageText = 'Rooms'
    } else {
      if (dataType === 'Rooms') backToPageText = 'Units'
      if (!selectedRoom && dataType === 'Beds') backToPageText = 'Units'
      if (selectedRoom && dataType === 'Beds') backToPageText = 'Rooms'
    }
  }
  let searchBarText
  if (path === 'locations') {
    searchBarText = dataType
  } else {
    searchBarText = path
  }

  let addButtonText = ''
  const showAddButton =
    (path === 'users' && userPermissions.includes('CreateUser')) ||
    (path === 'locations' &&
      dataType !== 'Sites' &&
      userPermissions.includes('CreateLocations'))
  if (path !== 'users') {
    if (
      (selectedRoom && dataType === 'Beds') ||
      (selectedUnit && dataType === 'Rooms') ||
      dataType === 'Units'
    ) {
      addButtonText = `Add ${dataType.slice(0, dataType.length - 1)}`
    } else {
      addButtonText = ''
    }
  } else {
    addButtonText = 'Add User'
  }
  return (
    <>
      {path === 'locations' && dataType !== 'Sites' && (
        <div
          className="neu-button pl3 pb3"
          style={{
            color: 'rgba(5,50,134)',
            fontWeight: 'bold',
            backgroundColor: 'white',
            fontSize: '16px',
            display: 'flex',
            hover: '',
          }}
          onClick={() => handleBackButton(backToPageText)}
        >
          <NeuButton color="primary-70" fill="flat">
            <NeuIcon slot="start" large="true">
              keyboard_arrow_left
            </NeuIcon>{' '}
            Back to {backToPageText}
          </NeuButton>
        </div>
      )}
      <div style={{ display: 'flex', width: '90%' }}>
        <div className="pl3 mt2" style={{ width: '85%' }}>
          <div className="ui left icon input Patient-Search w-100">
            <i className="search icon ml3" />
            {path !== 'users' && (
              <input
                type="text"
                style={{ width: '100%' }}
                placeholder={`Search ${searchBarText}`}
                value={searchText}
                onChange={(event) => {
                  const sanitizedSearchText = event.target.value
                  handleSearch(sanitizedSearchText)
                }}
              />
            )}
            {path === 'users' && (
              <input
                type="text"
                style={{ width: '100%' }}
                placeholder={`Search ${searchBarText}`}
                onChange={(event) => {
                  const sanitizedSearchText = event.target.value
                  handleSearch(sanitizedSearchText)
                }}
              />
            )}
          </div>
        </div>

        {showAddButton && addButtonText !== '' ? (
          <NeuButton
            color="primary-70"
            fill="flat-white"
            onClick={() => {
              if (path === 'users') {
                dispatch({
                  type: 'ui/MANAGE_SLIDING_PANE',
                  payload: {
                    open: true,
                    source: 'add-user-button',
                    data: {
                      authToken: authToken,
                      clinicalRoles: clinicalRoles,
                      selectedSite: selectedSite,
                    },
                  },
                })
              } else {
                if (adminFacilityCoid !== 'All') {
                  dispatch({
                    type: 'ui/MANAGE_SLIDING_PANE',
                    payload: {
                      open: true,
                      source: 'add-location',
                      data: {
                        authToken: authToken,
                        clinicalRoles: clinicalRoles,
                        selectedSite: selectedSite,
                        dataType: dataType,
                      },
                    },
                  })
                }
              }
              dispatch({
                type: 'ui/FLASH_MESSAGE',
                payload: { messages: [], type: '', uuid: '' },
              })
            }}
          >
            <NeuIcon>add</NeuIcon>
            {addButtonText}
          </NeuButton>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default SearchBar
