import { createSelector } from 'reselect'

export default createSelector(
  (state, props) => props,
  (state) => state.sites,
  (state, props) => props.navLinks,
  (state) => state.stagedStaffSlots,
  (state) => state.selectedStaffSlots.selectedStaffSlots,
  (state) => state.user,
  (state) => state.staffPool,
  (state) => state.userGroups,
  (state) => state.facilitySchedulerUsers,
  (state) => state.ui,
  (state) => state.units,
  (state) => state.locations,
  (
    props,
    sitesState,
    navLinksState,
    stagedStaffSlotsState,
    selectedStaffSlotsState,
    userState,
    staffPoolState,
    userGroupState,
    preferredUserState,
    uiState,
    unitState,
    locationState,
  ) => {
    const formattedSites = []
    sitesState.sites.forEach((site, index) => {
      formattedSites.push({
        key: index,
        facilityCoid: site.facilityCoid,
        facilityId: site.parent.id,
        fhirResourceUrl: site.fhirResourceUrl,
        locationType: site.locationType,
        value: site.siteId,
        text: site.displayName,
        id: site.id,
      })
    })
    const selSiteId = sitesState.selectedSiteId
    const selSite = sitesState.sites.filter((site) => {
      return site.siteId === selSiteId
    })

    const result = {
      ...props,
      user: userState,
      navLinks: navLinksState,
      sites: sitesState,
      formattedSites: formattedSites,
      selectedSite: selSite[0],
      selectedStaffSlots: selectedStaffSlotsState,
      authToken: userState.token,
      editing: stagedStaffSlotsState.editing,
      staffPool: staffPoolState,
      userGroups: userGroupState.userGroups,
      preferredStaff: preferredUserState.preferredStaff,
      ui: uiState,
      selSiteFsId:
        selSite[0] &&
        selSite[0].integrationProperties &&
        selSite[0].integrationProperties.FacilitySchedulerFacilityId,
      units: unitState,
      adminLocations: locationState.adminLocations,
    }
    return result
  },
)
