import styled from 'styled-components'

const Flex = styled.div`
    display: flex;
    flex-wrap: ${(props) => (props.wrap === 'true' ? 'wrap' : 'no-wrap')}
    flex-direction: ${(props) => props.direction};
`
export const FlatTabButton = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
`
export const UserChip = styled.div`
  whitespace: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export default Flex
