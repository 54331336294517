/**
 * Styling Imports
 * */
import {
  Parent,
  Triangle,
  Content,
  HiddenDiv,
  Time,
  Gradient,
} from './timeSelector.styles'

export const TimeSelector = ({ timeSelected }) => {
  const timeArray = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
  ]
  return (
    <Parent id="parent">
      <Triangle id="triangle" />
      <HiddenDiv>
        <Content id="content">
          <Gradient />
          {timeArray.map((time, index) => (
            <Time
              onClick={(e) => {
                e.persist()
                timeSelected(e.target.innerText)
              }}
              isLast={index === timeArray.length - 1}
              // key={index}
            >
              {time}
            </Time>
          ))}
        </Content>
      </HiddenDiv>
    </Parent>
  )
}
export const HourSelector = ({ hourSelected }) => {
  const timeArray = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ]
  return (
    <Parent id="parent">
      <Triangle id="triangle" />
      <HiddenDiv>
        <Content id="content">
          <Gradient />
          {timeArray.map((time, index) => (
            <Time
              onClick={(e) => {
                e.persist()
                hourSelected(e.target.innerText)
              }}
              isLast={index === timeArray.length - 1}
              key={time + index}
            >
              {time}
            </Time>
          ))}
        </Content>
      </HiddenDiv>
    </Parent>
  )
}

export const MinuteSelector = ({ minuteSelected }) => {
  const timeArray = ['00', '15', '30', '45']
  return (
    <Parent id="parent">
      <Triangle id="triangle" />
      <HiddenDiv>
        <Content id="content">
          <Gradient />
          {timeArray.map((time, index) => (
            <Time
              onClick={(e) => {
                e.persist()
                minuteSelected(e.target.innerText)
              }}
              isLast={index === timeArray.length - 1}
              key={time + index}
            >
              {time}
            </Time>
          ))}
        </Content>
      </HiddenDiv>
    </Parent>
  )
}
