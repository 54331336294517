import styled from 'styled-components'

// LeftSidePanel styling

export const Container = styled.div`
  width: 327px;
  display: flex;
  height: 825px;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-shadow: inset -10px 0px 5px -8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset -10px 0px 5px -8px rgba(0, 0, 0, 0.2);
  box-shadow: inset -10px 0px 5px -8px rgba(0, 0, 0, 0.2);
`
// Manage.js styling

export const DropShadowDiv = styled.div`
  -webkit-box-shadow: inset 0 20px 8px -20px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 20px 8px -20px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 20px 8px -20px rgba(0, 0, 0, 0.3);
  background-color: white;
  min-height: 1000px;
  max-height: 100%;
  display: flex;
  flex: 1;
`
