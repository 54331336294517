import Background from '../errorPages/Background.png'
import CareTeamLogo from '../errorPages/CareTeamLogo.svg'

const ServiceUnavailable = () => {
  return (
    <>
      <img
        style={{
          backgroundColor: '#E05929',
          minHeight: '100%',
          minWidth: '100%',
        }}
        src={Background}
        alt="HCA Background"
      />
      <div
        style={{
          top: '30%',
          left: '30%',
          position: 'absolute',
          padding: '50px',
          width: '650px',
          height: '500px',
        }}
      >
        <img
          style={{ paddingLeft: '170px', height: '18.95px' }}
          src={CareTeamLogo}
          alt="Care Team Logo"
        />
        <br />
        <br />
        <h1
          style={{
            paddingLeft: '115px',
            color: 'white',
            fontSize: '140px',
          }}
        >
          503
        </h1>
        <br />
        <br />
        <h3
          style={{
            paddingLeft: '90px',
            fontSize: '40px',
            fontWeight: 'bold',
            color: 'white',
          }}
        >
          Service Unavailable
        </h3>
        <p
          style={{
            paddingLeft: '40px',
            color: 'white',
            fontSize: '16px',
          }}
        >
          The server is unable to handle the request at the moment
        </p>

        <div style={{ paddingLeft: '180px' }}>
          <a href="/">
            <button
              style={{
                height: '40px',
                width: '126px',
                borderRadius: '5px',
                backgroundColor: ' #00558C',
                color: 'white',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              {' '}
              Go Home
            </button>
          </a>
        </div>
      </div>
    </>
  )
}

export default ServiceUnavailable
