import styled from 'styled-components'

import Flex from '../reusableComponents/styleLibrary'

// dataList

export const UserRow = styled.div`
  display: flex;
  border-top: 1px solid #bcbcbc;
  border-bottom: ${(props) => (props.last ? '1px solid #bcbcbc' : '')};
  position: relative;
`

export const ColumnDiv = styled.div`
  width: 120px;
  margin-left: 20px;
`

export const DisplayNameDiv = styled.div`
  color: rgba(5, 50, 134);
  font-family: HCA-Mark, Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
`

export const LocationDiv = styled.div`
  color: rgba(5, 50, 134);
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`
export const UnitContainer = styled.div`
  color: #1f2532;
  font-size: 14px;
  padding: 10px 10px 10px 15px;
  background: #f5f5f5;
  border-top: 1px solid #bcbcbc;
  border-bottom: 1px solid #bcbcbc;
  display: flex;
`

export const UnitName = styled.div`
  font-family: 'HCA-Mark-Bold';
  width: 50%;
  justify-content: flex-start;
`

export const DraftSaved = styled.div`
  font-family: 'HCA-Mark';
  display: flex;
  justify-content: flex-end;
  width: 50%;
`

export const NoDataMessage = styled.div`
  font-size: 12px;
  font-family: 'HCA-Mark';
  padding: 10px 10px 10px 15px;
`

export const PaginationContainer = styled(Flex)`
  justify-content: center;
  color: #1f2433;
  font-size: 16px;
  font-family: 'HCA-Mark';
`

export const PaginationLink = styled.div`
  padding-right: 5px;
  cursor: pointer;
  color: #00558c;
`

/**
 * Calculates the style object for a header in a table.
 * secondItemWidth is a temporary special case for the second column in the locationassignments table
 */
export function calculateHeaderStyle(props, header, columnHeaders, index) {
  let flexValue = `0 0 ${
    (((props.path === 'patients' &&
      (props.authorizedGlobalAdmin || props.authorizedLocalAdmin)) ||
    ((props.path === 'locationassignments' ||
      props.path === 'patientassignments') &&
      !props.ui.isDraftAssignment) ||
    (props.path !== 'patients' &&
      (props.authorizedGlobalAdmin || props.authorizedLocalAdmin))
      ? 92
      : 100) *
      header.multiplier) /
      columnHeaders.reduce(
        (acc, header, index) => acc + header.multiplier,
        0,
      ) || 0
  }%`

  const minWidth =
    props.path === 'locationassignments' &&
    index === 1 &&
    props.ui.departmentSelected === 'Nursing'
      ? '272px'
      : '0px'

  return {
    flex: `${flexValue}`,
    ...(header.id
      ? {
          display: 'flex',
          justifyContent: 'center',
        }
      : {}),
    ...(header.label === 'ASSIGNED TO BED' ? { marginLeft: '20px' } : {}),
    paddingLeft: '10px',
    minWidth,
    maxWidth:
      props.path === 'locationassignments' &&
      index === 1 &&
      props.ui.departmentSelected === 'Nursing'
        ? '300px'
        : undefined,
    paddingTop: '15px',
    paddingRight: '15px',
    paddingLeft:
      index === 0 &&
      !(
        props.path === 'locationassignments' ||
        props.path === 'patientassignments'
      )
        ? '15px'
        : header.label === 'BEDS'
        ? '10px'
        : '0px',
  }
}
