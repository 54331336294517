const initialState = {
  staffPool: [],
  selectedStaffMember: [],
  fetching: false,
  error: 404,
  staffSearchResults: [],
  bubbleMetadata: [],
  filteredBubbleMetadata: {
    firstName: '',
    lastName: '',
    assignments: [],
  },
  bubbleMetadataError: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'assignments/CLEAR_ALL_SELECTED_STAFF':
      return {
        ...state,
        selectedStaffMember: [],
      }
    case 'assignments/FETCH_STAFF_POOL':
      return { ...state, fetching: true }
    case 'assignments/FETCH_STAFF_POOL_ERROR':
      return { ...state, error: action.payload, fetching: false }
    case 'assignments/FETCH_STAFF_POOL_RECEIVED':
      let payload = []
      if (action.payload.length > 0) {
        action.payload.forEach((staff, index) => {
          const fullName = staff.lastName + ', ' + staff.firstName

          payload.push(Object.assign({}, staff, { fullName }))
        })
      }
      return { ...state, staffPool: payload, fetching: false }
    case 'assignments/SET_SELECTED_STAFF':
      return { ...state, selectedStaffMember: action.payload }
    case 'assignments/SEARCH_RESULTS_STAFF':
      return { ...state, staffSearchResults: action.payload }
    case 'assignments/FETCH_BUBBLE_METADATA':
      return { ...state }
    case 'assignments/FETCH_BUBBLE_METADATA_RECEIVED':
      return {
        ...state,
        bubbleMetadata: action.payload,
      }
    case 'assignments/FETCH_BUBBLE_METADATA_ERROR':
      return {
        ...state,
        bubbleMetadataError: action.payload,
      }
    case 'assignments/CLEAR_BUBBLE_METADATA':
      return {
        ...state,
        bubbleMetadata: {},
      }
    case 'assignments/SET_FILTERED_BUBBLE_METADATA':
      return {
        ...state,
        filteredBubbleMetadata: action.payload,
      }
    default:
      return state
  }
}
