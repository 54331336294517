export const filterByKeyword = (data, keyword) => {
  if (!keyword) return data
  let keywords
  let sanitizedKeyword
  const hasComma = keyword.match(/,/g)
  const hasSpace = keyword.match(/\w{0,}\s\w{0,}/g)

  if (hasSpace || hasComma) {
    keywords = hasComma ? keyword.split(',') : keyword.split(' ')
    keywords = keywords.map((string) => string.toLowerCase().trim())
  } else {
    sanitizedKeyword = keyword.toLowerCase().trim()
    keywords = ['', '']
  }
  const [first, second] = keywords !== undefined && keywords
  if (keyword && data.length === 0) return data
  const dataKeys = (() => Object.keys(data[0]))()
  const results = []
  data.forEach((datum) => {
    dataKeys.forEach((key) => {
      if (typeof datum[key] === 'string') {
        const lowerCaseDatum = datum[key].toLowerCase().trim()
        if (lowerCaseDatum.includes(sanitizedKeyword || first || second)) {
          results.push(datum)
        }
      } else {
        if (datum[key] > 0) {
          const dks = (() => Object.keys(datum[key][0]))()
          datum[key].forEach((d) => {
            dks.forEach((k) => {
              if (typeof d[k] === 'string') {
                const lowerCaseDatum = d[k].toLowerCase().trim()
                if (
                  lowerCaseDatum.includes(sanitizedKeyword || first || second)
                ) {
                  results.push(datum)
                }
              }
            })
          })
        }
      }
    })
  })

  return results
}
