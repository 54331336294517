/**
 * External Imports
 */
import { NeuIcon, NeuButton } from '@neutron/react'
/**
 * Internal Imports
 */
import Checkbox from '../neutronComponents/checkbox/checkbox'

import {
  setGlobalUserSettings,
  toggleShowTutorial,
} from '../../../redux/actions/user'
/**
 * Style Imports
 */
import {
  OverwriteModal,
  ConfirmText,
  ButtonsDiv,
} from './overwritingAssignment.styles'
import { FlatTabButton } from '../styleLibrary'

const OverwritingAssignment = (props) => {
  const { showTutorial } = props.globalSettingsConfig.globalSettings

  const updateGlobalSettings = (bool) => {
    const { globalSettings } = props.globalSettingsConfig
    const updatedSettings =  {
      ...globalSettings,
      showTutorial: !bool
    }
    const settingsPackage =  {
      ...props.globalSettingsConfig,
      globalSettings: updatedSettings,
    }

    props.dispatch(setGlobalUserSettings(settingsPackage))
  }

  const handleClick = () => {
    updateGlobalSettings(showTutorial)
    props.dispatch(toggleShowTutorial(!showTutorial))
  }

  const showHideOverlapClass = props.showOverlapAssignment
    ? 'modal display-block'
    : 'modal display-none'

  return (
    <div className={showHideOverlapClass}>
      <OverwriteModal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '10px',
            paddingLeft: '10px',
          }}
        >
          <h6 style={{ fontFamily: 'HCA-Mark-Bold' }}>Assignment Override</h6>
          <FlatTabButton
            onClick={() => {
              props.dispatch({
                type: 'stagedStaffSlots/ASSIGNING',
                payload: false,
              })
              props.hideOverlapModal()
            }}
          >
            <NeuIcon
              style={{
                color: '#898B8E',
                cursor: 'pointer',
              }}
            >
              cancel
            </NeuIcon>
          </FlatTabButton>
        </div>
        <div
          style={{
            display: 'flex',
            paddingLeft: '10px',
          }}
        >
          <ConfirmText>
            You have selected an assignment that overlaps with another staff
            member. Proceed?
          </ConfirmText>
        </div>

        <div
          style={{
            display: 'flex',
            paddingTop: '10px',
            paddingLeft: '10px',
          }}
        >
          <Checkbox
            id="displayMessage"
            click={handleClick}
            isSelected={!showTutorial}
            custom={false}
          />

          <label style={{ marginLeft: '10px' }} htmlFor="displayMessage">
            Do not show me this warning again
          </label>
        </div>

        <ButtonsDiv>
          <NeuButton
            fill="flat-white"
            className="neu-button"
            color="red-70"
            style={{ fontFamily: 'HCA-Mark-Bold' }}
            onClick={() => {
              props.dispatch({
                type: 'stagedStaffSlots/ASSIGNING',
                payload: false,
              })
              props.hideOverlapModal()
            }}
          >
            Cancel
          </NeuButton>
          <NeuButton
            className="neu-button"
            style={{
              backgroundColor: '#00558C',
              color: 'white',
              fontFamily: 'HCA-Mark-Bold',
            }}
            onClick={() => {
              // amplitude
              //   .getInstance()
              //   .logEvent(
              //     'OVERRIDE_MODAL_ASSIGN_BUTTON_CLICKED',
              //     props.eventProperties,
              //   )
              updateGlobalSettings(!showTutorial)
              props.assignSlots()
            }}
          >
            Assign
          </NeuButton>
        </ButtonsDiv>
      </OverwriteModal>
    </div>
  )
}

export default OverwritingAssignment
