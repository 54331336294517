export default function formatSnapshot(report, time, date) {
  if (!Array.isArray(report)) return []
  if (!(report.length > 0)) return []

  const headers = [
    {
      key: 'unit',
      label: 'Unit',
      xPos: 12.7,
      width: 15,
    },
    {
      key: 'bed',
      label: 'Bed',
      xPos: 28,
      width: 20,
    },
    {
      key: 'isolationStatus',
      label: 'Isolation Status',
      xPos: 20,
      width: 20,
    },
    {
      key: 'patientName',
      subKey: 'patientMeta',
      label: 'Patient',
      xPos: 50,
      width: 40,
    },
    {
      key: 'careTeam',
      label: 'Care Team',
      xPos: 93,
    },
    {
      key: 'lastUpdated',
      label: 'Team Last Updated',
      xPos: 228,
      width: 50,
    },
  ]

  const reportTime = `${time} on ${date}`

  return {
    report,
    headers,
    fileName: 'Snapshot Report at ' + reportTime,
    meta: {
      reportTime,
    },
  }
}
