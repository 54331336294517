import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import freeze from 'redux-freeze'
import _ from 'lodash'
import rootReducer from './rootReducer'
//TODO uncomment this for PRODUCTION
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { composeWithDevTools } from 'redux-devtools-extension'

// const middlewares = _.compact([thunk, freeze, logger])

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })
const middlewares = _.compact([thunk, freeze])
const createStoreWithMiddleware = composeEnhancers(
  applyMiddleware(...middlewares),
)(createStore)

const store = createStoreWithMiddleware(rootReducer)

export default store
