/**
 * External Imports
 * */
import { useEffect } from 'react'
/**
 * Internal Imports
 * */
import Loading from '../reusableComponents/neutronComponents/Spinner'
import {
  AuthorizationServiceConfiguration,
  RedirectRequestHandler,
} from '@openid/appauth'
import { AuthorizationRequest } from '@openid/appauth/built/authorization_request'
/**
 * Static  Imports
 * */
import configInfo from '../../pingConfig.json'

const developmentEnvironment = process.env.REACT_APP_ENV
const devENV = developmentEnvironment.toLowerCase()
const envRedirect = window.location.origin + '/redirect'
let client_id
let openIdConnectUrl
if (devENV.includes('local')) {
  openIdConnectUrl = configInfo.devqaOpenIdConnectUrl
  client_id = configInfo.devqaClient_id
} else if (devENV.includes('dev')) {
  openIdConnectUrl = configInfo.devqaOpenIdConnectUrl
  client_id = configInfo.devqaClient_id
} else if (devENV.includes('qa')) {
  openIdConnectUrl = configInfo.devqaOpenIdConnectUrl
  client_id = configInfo.devqaClient_id
} else if (devENV.includes('prod')) {
  openIdConnectUrl = configInfo.prodOpenIdConnectUrl
  client_id = configInfo.prodClient_id
} else {
  console.warn('No environment set')
}

const Authenticate = () => {
  useEffect(() => {
    fetchConfig().then((res) => {
      makeAuthRequest(res)
    })
  }, [])
  const fetchConfig = () => {
    return AuthorizationServiceConfiguration.fetchFromIssuer(openIdConnectUrl)
      .then((response) => {
        console.log('Fetched service configuration', response)
        // this.configuration = response
        return response
      })
      .catch((error) => {
        console.log('Something bad happened', error)
        throw error
      })
  }
  const makeAuthRequest = (configuration) => {
    // uses a redirect flow
    const authorizationHandler = new RedirectRequestHandler()
    const { scope, response_type, state, extras } = configInfo
    // create a request
    const request = new AuthorizationRequest({
      client_id,
      redirect_uri: envRedirect,
      scope,
      response_type,
      state,
      extras,
    })

    // make the authorization request
    authorizationHandler.performAuthorizationRequest(configuration, request)
  }
  return (
    <div
      style={{
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loading square="100px" />
    </div>
  )
}

export default Authenticate
