import styled from 'styled-components'
import './radio.scss'

const Radio = (props) => {
  return (
    <RadioDiv custom={props.custom} onClick={props.click}>
      <RadioButton isSelected={props.isSelected} />
    </RadioDiv>
  )
}

export default Radio

const RadioDiv = styled.div.attrs({
  className: 'radio',
})``
const RadioButton = styled.div.attrs((props) => ({
  className: props.isSelected
    ? 'radio__button radio--is-checked  radio--is-checked :after'
    : 'radio__button',
  tabIndex: '0',
  role: 'radio',
  'aria-checked': props.isSelected,
}))`
  width: 20px;
  height: 20px;
  background: transparent;
  outline: none;
`
