import styled from 'styled-components'
import { NeuChip } from '@neutron/react'

export const BasicChip = styled(NeuChip)`
  box-sizing: border-box;
  display: flex;
  min-width: 50px;
  width: 140px;
  height: 32px;
  border-radius: 36px;
  margin: 10px;
  padding-left: 3px !important;
`

export const RoleTypeBubble = styled.div`
  width: max-content;
  padding: 2.5px 9px 0px;
  border-radius: 15px;
  height: 26px;
`

export const AssignmentText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 3px 0 0 5px;
  padding-bottom: 2px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'HCA-Mark';
`
