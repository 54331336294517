import { publish } from '../redux/actions/stagedStaffSlots'
import store from '../redux/store'

export const publishFunction = (
  stagedStaffSlots,
  page,
  siteId,
  selectedUnitId,
  hideUnpublishedModal,
  authToken,
  ui,
  allUnitsSelected,
) => {
  const staged = stagedStaffSlots

  const publishFormat = []

  staged.forEach((slot) => {
    const assignmentData =
      page === 'locations'
        ? slot.assignedToLocation.assignmentId
        : slot.assignedToPatient.assignmentId

    let action
    let existingId
    if (slot.action === 'remove' && assignmentData) {
      action = 'Delete'
      existingId = assignmentData
    } else if (slot.action === 'assign' && assignmentData) {
      action = 'Update'
      existingId = assignmentData
    } else if (slot.action === 'assign') {
      action = 'Insert'
      existingId = null
    } else {
      // if there's any other cases then it's a staged slot that shouldn't be there
      // for example a bulk 'unassign' where an unassigned slot was clicked as well
      return
    }

    const userId = slot[`assignedTo${slot.manifest.Type}`].userId

    let assignmentType
    if (page === 'locations') {
      assignmentType = 'Location'
    } else if (page === 'patients') {
      assignmentType = 'Patient'
    }

    const pushSlot = {
      Action: action,
      UserId: userId,
      Type: assignmentType,
      EntityId: slot.manifest.EntityId,
      AssignmentSlot: slot.manifest.AssignmentSlot,
    }
    if (existingId) {
      pushSlot.Id = existingId
    }

    if (userId) {
      pushSlot.UserId = userId
    }

    publishFormat.push(pushSlot)
  })
  // hideUnpublishedModal()

  store.dispatch(
    publish(
      siteId,
      authToken,
      publishFormat,
      selectedUnitId,
      ui,
      allUnitsSelected,
    ),
  )
}
