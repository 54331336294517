import Background from '../errorPages/Background.png'
import CareTeamLogo from '../errorPages/CareTeamLogo.svg'

const TimeOutError = () => {
  return (
    <>
      <img
        style={{
          backgroundColor: '#E05929',
          minHeight: '100%',
          minWidth: '100%',
        }}
        src={Background}
        alt="HCA Background"
      />
      <div style={{ top: '30%', left: '40%', position: 'absolute' }}>
        <div style={{ alignItems: 'center' }}>
          <img
            style={{ paddingLeft: '100px', height: '18.95px' }}
            src={CareTeamLogo}
            alt="Care Team Logo"
          />
          <br />
          <br />

          <h3
            style={{
              fontSize: '30px',
              fontWeight: 'bold',
              color: 'white',
            }}
          >
            Your request has timed out.
          </h3>
          <p
            style={{
              color: 'white',
              fontSize: '16px',
            }}
          >
            Try restarting the application.
          </p>
          <br />
          <div style={{ paddingLeft: '100px' }}>
            <a href="/">
              <button
                style={{
                  height: '40px',
                  width: '126px',
                  borderRadius: '5px',
                  backgroundColor: ' #00558C',
                  color: 'white',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                Click Here
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default TimeOutError
