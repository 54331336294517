export const filterAssignments = (bubbleMetadata) => {
  if (bubbleMetadata === undefined) return []
  const assignmentsArr = []
  if (bubbleMetadata.length > 0) {
    for (let i = 0; i < bubbleMetadata.length; i++) {
      if (
        bubbleMetadata[i].location.assignments !== null &&
        bubbleMetadata[i].location.status === 'Active'
      )
        for (
          let j = 0;
          j < bubbleMetadata[i].location.assignments.length;
          j++
        ) {
          if (
            bubbleMetadata[i].location.assignments[j].assignedSlot
              .abbreviation !== 'CNC'
          ) {
            const obj = {}
            obj.userId = bubbleMetadata[i].location.assignments[j].user.id
            obj.role =
              bubbleMetadata[i].location.assignments[
                j
              ].assignedSlot.abbreviation
            obj.location = bubbleMetadata[i].location.displayName
            obj.patientFirstName =
              bubbleMetadata[i].patient !== null
                ? bubbleMetadata[i].patient.firstName
                : ''
            obj.patientLastName =
              bubbleMetadata[i].patient !== null
                ? bubbleMetadata[i].patient.lastName
                : ''
            assignmentsArr.push(obj)
          }
        }

      if (
        bubbleMetadata[i].patient !== null &&
        bubbleMetadata[i].patient.assignments !== null
      ) {
        for (let k = 0; k < bubbleMetadata[i].patient.assignments.length; k++) {
          if (
            bubbleMetadata[i].patient.assignments[k].assignedSlot
              .abbreviation !== 'CNC'
          ) {
            const obj = {}
            obj.userId = bubbleMetadata[i].patient.assignments[k].user.id
            obj.role =
              bubbleMetadata[i].patient.assignments[k].assignedSlot.abbreviation
            obj.location = bubbleMetadata[i].patient.location.displayName
            obj.patientFirstName =
              bubbleMetadata[i].patient !== null
                ? bubbleMetadata[i].patient.firstName
                : ''
            obj.patientLastName =
              bubbleMetadata[i].patient !== null
                ? bubbleMetadata[i].patient.lastName
                : ''
            assignmentsArr.push(obj)
          }
        }
      }
    }
  }

  assignmentsArr.sort((a, b) => secondarySort(a, b))
  return assignmentsArr
}

// Sort by first property, then second
// Source: https://stackoverflow.com/questions/9175268/javascript-sort-function-sort-by-first-then-by-second
export const secondarySort = (a, b) => {
  if (a.role > b.role) {
    return 1
  } else if (a.role < b.role) {
    return -1
  }

  if (a.location < b.location) {
    return -1
  } else if (a.location > b.location) {
    return 1
  } else {
    return 0
  }
}
