import axios from '../../utils/axiosWrapper'
import environment from '../../environment'
import { filterLocations } from '../../utils/filterLocations'
import ErrorCheck from '../../utils/errorCheck'
import { discardStagedSlots } from './stagedStaffSlots'
import { setSelectedStaffSlot } from './selectedStaffSlots'
import { fetchLocationsWithMultipleUnits } from './locations'
import { fetchStaffPool, getStaffAssignmentMetadata } from './staffPool'

const serverUrl = environment._serverUrl

export const fetchDraftAssignments =
  (authToken, siteId, unitIds, ui, locations, selectedDept) => (dispatch) => {
    let urlForAuthUser
    urlForAuthUser = serverUrl + `Assignment/GetDraftAssignmentsByDepartment`
    const data = unitIds.filter((id) => id != null)
    const departmentName =
      selectedDept && ui.departmentSelected !== selectedDept
        ? selectedDept
        : ui.departmentSelected

    dispatch({ type: 'draftAssignments/FETCH_DRAFT_ASSIGNMENTS' })

    axios({
      method: 'post',
      url: urlForAuthUser,
      headers: {
        authorization: authToken,
        siteId,
        Pragma: 'no-cache',
      },
      data: {
        Department: departmentName,
        UnitIds: data,
      },
    })
      .then(function (response) {
        let draftLocations = []
        let allDraftAssignments
        //map out the assignments
        let unitAssignments = response.data.map((item) => item.assignments)
        //merge them into 1 array
        allDraftAssignments = [].concat.apply([], unitAssignments)

        locations.forEach((loc) => {
          let draftAssignments = allDraftAssignments.filter(
            (assignment) =>
              assignment.action !== 'Delete' &&
              assignment.entityId === loc.location.id,
          )
          let newLocation = Object.assign({}, loc.location, {
            assignments: draftAssignments,
          })
          draftLocations.push({ location: newLocation, patient: loc.patient })
        })

        dispatch({
          type: 'draftAssignments/FETCH_DRAFT_ASSIGNMENT_LOCATIONS_RECEIVED',
          payload: draftLocations,
        })

        dispatch({
          type: 'draftAssignments/FETCH_DRAFT_ASSIGNMENTS_RECIEVED',
          payload: response.data,
        })

        const bedsSearchResults = filterLocations(ui.searchText, draftLocations)

        if (ui.searching) {
          dispatch({
            type: 'locations/SET_SEARCH',
            payload: {
              searching: true,
              draftBedSearchResults:
                bedsSearchResults.length > 0 ? bedsSearchResults : [],
              searchText: ui.searchText,
            },
          })
        }
      })
      .catch(function (error) {
        ErrorCheck(error)
        dispatch({ type: 'draftAssignments/FETCH_DRAFT_ASSIGNMENTS' })

        if (error.response.status === 404) {
          let emptyDraftLocations = []
          locations.forEach((loc) => {
            let newLocation = Object.assign({}, loc.location, {
              assignments: [],
            })
            emptyDraftLocations.push({
              location: newLocation,
              patient: loc.patient,
            })
          })
          dispatch({
            type: 'draftAssignments/FETCH_DRAFT_ASSIGNMENT_LOCATIONS_RECEIVED',
            payload: emptyDraftLocations,
          })
          dispatch(setSelectedStaffSlot([]))
          dispatch({
            type: 'draftAssignments/FETCH_DRAFT_ASSIGNMENTS_RECIEVED',
            payload: [],
          })
        } else {
          dispatch({
            type: 'draftAssignments/FETCH_DRAFT_ASSIGNMENTS_ERROR',
            payload: error.response,
          })
        }
      })
  }

export const saveDraftAssignments =
  (
    authToken,
    siteId,
    unitIds,
    ui,
    selectedStaffSlots,
    departmentSelected,
    staffPool,
    draftLocations,
  ) =>
  (dispatch) => {
    let urlForAuthUser
    urlForAuthUser = serverUrl + `Assignment/UpdateDraftAssignments`
    const staffSelected = staffPool.selectedStaffMember[0]

    const data = unitIds.map((unit) => {
      let assignments
      const additionalAssignments = selectedStaffSlots
        .filter((slot) => unit === slot.UnitId)
        .map((assignment) => ({
          Action: 'Insert',
          User: {
            firstName: staffSelected.firstName,
            lastName: staffSelected.lastName,
            hca34: staffSelected.hca34,
            id: staffSelected.id,
          },
          Type: assignment.manifest.Type,
          EntityId: assignment.manifest.EntityId,
          AssignedSlot: {
            DisplayName: assignment.manifest.AssignmentSlot.DisplayName,
            SystemName: assignment.manifest.AssignmentSlot.SystemName,
            Abbreviation: assignment.manifest.AssignmentSlot.Abbreviation,
          },
        }))
      const prevAssignments = draftLocations
        .filter((slot) => unit === slot.location.unit.id)
        .reduce(
          (acc, loc) => [
            ...acc,
            ...loc.location.assignments.map((assignment) => ({
              Action: 'Insert',
              User: {
                firstName: assignment.user.firstName,
                lastName: assignment.user.lastName,
                hca34: assignment.user.hca34,
                id: assignment.user.id,
              },
              Type: assignment.type,
              EntityId: assignment.entityId,
              AssignedSlot: {
                DisplayName: assignment.assignedSlot.displayName,
                SystemName: assignment.assignedSlot.systemName,
                Abbreviation: assignment.assignedSlot.abbreviation,
              },
            })),
          ],
          [],
        )

      if (prevAssignments.length > 0) {
        assignments = prevAssignments.concat(additionalAssignments)
      } else {
        assignments = additionalAssignments
      }

      return {
        Department: departmentSelected,
        UnitId: unit,
        IsCurrentDraft: true,
        Assignments: assignments,
      }
    })

    dispatch({ type: 'draftAssignments/SAVE_DRAFT_ASSIGNMENTS' })
    axios({
      method: 'post',
      url: urlForAuthUser,
      data: data,
      headers: {
        authorization: authToken,
        siteId,
        Pragma: 'no-cache',
      },
    })
      .then(function (response) {
        dispatch(
          fetchDraftAssignments(authToken, siteId, unitIds, ui, draftLocations),
        )

        dispatch({
          type: 'draftAssignments/SAVE_DRAFT_ASSIGNMENTS_RECEIVED',
          payload: response.data.resource,
        })

        dispatch(discardStagedSlots())

        const bedsSearchResults = filterLocations(ui.searchText, draftLocations)
        if (ui.searching) {
          dispatch({
            type: 'locations/SET_SEARCH',
            payload: {
              searching: true,
              draftBedSearchResults:
                bedsSearchResults.length > 0 ? bedsSearchResults : [],
              searchText: ui.searchText,
            },
          })
        }
      })
      .catch(function (error) {
        console.log('Error: ', error)
        ErrorCheck(error)

        if (error.response.status === 404) {
          dispatch({
            type: 'draftAssignments/SAVE_DRAFT_ASSIGNMENTS_RECEIVED',
            payload: [],
          })
        } else {
          dispatch({
            type: 'draftAssignments/SAVE_DRAFT_ASSIGNMENTS_ERROR',
            payload: error.response,
          })
        }
      })
  }

export const unassignDraftAssignments =
  (
    authToken,
    siteId,
    unitIds,
    ui,
    selectedStaffSlots,
    departmentSelected,
    draftLocations,
  ) =>
  (dispatch) => {
    let urlForAuthUser
    urlForAuthUser = serverUrl + `Assignment/UpdateDraftAssignments`

    const data = unitIds.reduce((acc, unit) => {
      const removedAssignments = selectedStaffSlots
        .filter(
          (slot) =>
            slot.assignedToLocation.assignmentId && unit === slot.UnitId,
        )
        .map((assignment) => ({
          Action: 'Delete',
          User: {
            id: assignment.assignedToLocation.userId,
          },
          Type: assignment.manifest.Type,
          EntityId: assignment.manifest.EntityId,
          AssignedSlot: {
            DisplayName: assignment.manifest.AssignmentSlot.DisplayName,
            SystemName: assignment.manifest.AssignmentSlot.SystemName,
            Abbreviation: assignment.manifest.AssignmentSlot.Abbreviation,
          },
        }))

      return removedAssignments.length > 0
        ? [
            ...acc,
            {
              Department: departmentSelected,
              UnitId: unit,
              IsCurrentDraft: true,
              Assignments: removedAssignments,
            },
          ]
        : acc
    }, [])

    dispatch({ type: 'draftAssignments/SAVE_DRAFT_ASSIGNMENTS' })
    axios({
      method: 'post',
      url: urlForAuthUser,
      data: data,
      headers: {
        authorization: authToken,
        siteId,
        Pragma: 'no-cache',
      },
    })
      .then(function (response) {
        dispatch(
          fetchDraftAssignments(authToken, siteId, unitIds, ui, draftLocations),
        )

        dispatch({
          type: 'draftAssignments/SAVE_DRAFT_ASSIGNMENTS_RECEIVED',
          payload: response.data.resource,
        })

        dispatch(discardStagedSlots())
        dispatch(setSelectedStaffSlot([]))

        const bedsSearchResults = filterLocations(ui.searchText, draftLocations)
        if (ui.searching) {
          dispatch({
            type: 'locations/SET_SEARCH',
            payload: {
              searching: true,
              draftBedSearchResults:
                bedsSearchResults.length > 0 ? bedsSearchResults : [],
              searchText: ui.searchText,
            },
          })
        }
      })
      .catch(function (error) {
        console.log('Error: ', error)
        ErrorCheck(error)

        if (error.response.status === 404) {
          dispatch({
            type: 'draftAssignments/SAVE_DRAFT_ASSIGNMENTS_RECEIVED',
            payload: [],
          })
        } else {
          dispatch({
            type: 'draftAssignments/SAVE_DRAFT_ASSIGNMENTS_ERROR',
            payload: error.response,
          })
        }
      })
  }

export const discardDraftAssignments =
  (authToken, siteId, unitIds, ui, draftLocations) => (dispatch) => {
    let urlForAuthUser
    urlForAuthUser = serverUrl + `Assignment/UpdateDraftAssignments`
    let data = []

    unitIds.forEach((unit) => {
      data.push({
        Department: ui.departmentSelected,
        UnitId: unit,
        IsCurrentDraft: true,
        Assignments: [],
      })
    })

    dispatch({ type: 'draftAssignments/DISCARD_DRAFT_ASSIGNMENTS' })
    axios({
      method: 'post',
      url: urlForAuthUser,
      data: data,
      headers: {
        authorization: authToken,
        siteId,
        Pragma: 'no-cache',
      },
    })
      .then(function (response) {
        dispatch(
          fetchDraftAssignments(authToken, siteId, unitIds, ui, draftLocations),
        )

        dispatch({
          type: 'draftAssignments/DISCARD_DRAFT_ASSIGNMENTS_SUCCESS',
          payload: response,
        })
      })
      .catch(function (error) {
        ErrorCheck(error)

        if (error.response.status === 404) {
          dispatch({
            type: 'draftAssignments/SAVE_DRAFT_ASSIGNMENTS_RECEIVED',
            payload: [],
          })
        } else {
          dispatch({
            type: 'draftAssignments/SAVE_DRAFT_ASSIGNMENTS_ERROR',
            payload: error.response,
          })
        }
      })
  }

export const publishDraftAssignments =
  (authToken, siteId, unitIds, ui, draftLocations) => (dispatch) => {
    let urlForAuthUser
    urlForAuthUser = serverUrl + `Assignment/PublishDraftAssignments`

    const data = {
      Department: ui.departmentSelected,
      UnitIds: unitIds.filter((id) => id != null),
    }
    dispatch({ type: 'draftAssignments/PUBLISH_DRAFT_ASSIGNMENTS' })

    axios({
      method: 'post',
      url: urlForAuthUser,
      data: data,
      headers: {
        authorization: authToken,
        siteId,
        Pragma: 'no-cache',
      },
    })
      .then(function (response) {
        dispatch(
          fetchLocationsWithMultipleUnits(authToken, siteId, unitIds, ui),
        )

        dispatch({
          type: 'draftAssignments/PUBLISH_DRAFT_ASSIGNMENTS_RECEIVED',
          payload: response.data,
        })

        dispatch({
          type: 'stagedStaffSlots/PUBLISH_SUCCESS',
          payload: response.data,
        })
        dispatch({ type: 'stagedStaffSlots/PUBLISH', payload: false })

        dispatch(discardStagedSlots())
        dispatch(fetchStaffPool(authToken, siteId))
        dispatch(
          getStaffAssignmentMetadata({
            authorization: authToken,
            siteId,
          }),
        )

        const bedsSearchResults = filterLocations(ui.searchText, draftLocations)
        if (ui.searching) {
          dispatch({
            type: 'locations/SET_SEARCH',
            payload: {
              searching: true,
              draftBedSearchResults:
                bedsSearchResults.length > 0 ? bedsSearchResults : [],
              searchText: ui.searchText,
            },
          })
        }
      })
      .catch(function (error) {
        ErrorCheck(error)

        if (error.response.status === 404) {
          dispatch({
            type: 'draftAssignments/PUBLISH_DRAFT_ASSIGNMENTS_RECEIVED',
            payload: [],
          })
        } else {
          dispatch({
            type: 'draftAssignments/PUBLISH_DRAFT_ASSIGNMENTS_ERROR',
            payload: error.response,
          })
        }
      })
  }

export const fetchPreferredDraftStaffPool =
  ({ authToken, fsDepartmentIds, siteId }) =>
  (dispatch) => {
    const baseURL = serverUrl.replace('api/', '')
    let urlForAuthUser = `${baseURL}adapter/fs/preferredusers?hours=14`

    if (authToken && fsDepartmentIds.length > 0 && siteId) {
      dispatch({
        type: 'assignments/FETCH_PREFERRED_STAFF_POOL_FETCHING',
        payload: true,
      })

      axios({
        method: 'POST',
        url: urlForAuthUser,
        data: JSON.stringify(fsDepartmentIds),
        headers: {
          Authorization: authToken,
          siteId,
          Pragma: 'no-cache',
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then(function (response) {
          dispatch({
            type: 'assignments/SPECIFIC_PREFERRED_STAFF',
            payload: response.data,
          })
          dispatch({
            type: 'assignments/FETCH_PREFERRED_STAFF_POOL_FETCHING',
            payload: false,
          })
        })
        .catch(function (error) {
          ErrorCheck(error)
          console.log('error', error)
          dispatch({
            type: 'assignments/FETCH_PREFERRED_STAFF_POOL_FETCHING',
            payload: false,
          })
          dispatch({
            type: 'assignments/FETCH_PREFERRED_STAFF_POOL_ERROR',
            payload: error.response,
          })
        })
      dispatch({
        type: 'assignments/FETCH_PREFERRED_STAFF_POOL_FETCHING',
        payload: false,
      })
    } else {
      dispatch({
        type: 'assignments/SPECIFIC_PREFERRED_STAFF',
        payload: [],
      })
    }
  }
