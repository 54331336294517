/**
 * External Imports
 * */
import { useState, useEffect } from 'react'
import { useAnalyticsApi } from '@shared-web-analytics/react/dist'

import { CSVLink } from 'react-csv'
import { NeuIcon } from '@neutron/react'
import moment from 'moment'
/**
 * Internal Imports
 * */
import store from '../../../redux/store'
import Events from '../../../analytics/events'

/**
 * Styling Imports
 * */
import { CSVPrint } from './print.styles'
import Loading from '../neutronComponents/Spinner'

const PrintCSV = ({ page, list, staffSlots, fetchingUserExport, coId }) => {
  const [headers, setHeaders] = useState([])
  const [dataToDownload, setDataToDownload] = useState([])
  const [filename, setFileName] = useState('')

  const { logTrackingEvent } = useAnalyticsApi()

  useEffect(() => {
    if (!dataToDownload.length) {
      download({ page, list, staffSlots })
    }
  }, [])

  let download = () => {
    const upState = store.getState()

    if (page === 'users') {
      setDataToDownload(list)
    } else {
      const userGroup = upState.userGroups.userGroups.filter(
        (groupId) => groupId.id === upState.userGroups.selectedUserGroupId,
      )
      const userGroupName = userGroup.length > 0 && userGroup[0].roleGroup

      // TODO: unused code, keeping if needed in future

      // const unit = upState.units.units.filter(
      //   (u) => u.id === upState.units.selectedUnitId,
      // )
      // const unitName = unit[0] ? unit[0].displayName : 'All'

      const date = moment().format('YYYYMMDDHHmmss')
      setFileName(`CareTeam-${userGroupName}${date}.csv`)
      const headers = [
        { label: 'Unit', key: 'unit' },
        { label: 'Bed', key: 'displayName' },
        { label: 'Patient Name', key: 'name' },
        { label: 'Isolation Status', key: 'isolationStatus' },
      ]

      const staffSlotHeaders = []
      staffSlots.forEach((staffSlot) => {
        const removePeriodsFromSystemName = staffSlot.systemName
          .split('.')
          .join('')
        const addStaffSlot = {
          label: staffSlot.abbreviation,
          key: removePeriodsFromSystemName,
          systemName: staffSlot.systemName || '',
        }
        staffSlotHeaders.push(addStaffSlot)
      })

      const notesHeader = { label: 'Notes', key: ' ' }
      staffSlotHeaders.push(notesHeader)

      const finalHeaders = headers.concat(staffSlotHeaders)
      setHeaders(finalHeaders)
      const printData = []
      list.forEach((row) => {
        let isolationStatus = row.patient.isolationStatus
        let addRow = {
          unit: ' ' + row.location.unit || '',
          displayName: ' ' + row.location.bed || '',
          name: row.patient.name || '',
          isolationStatus: isolationStatus || '',
        }
        printData.push(addRow)

        staffSlotHeaders.forEach((headerItem) => {
          const slotHeaderItem = headerItem.systemName

          const userName =
            (row[slotHeaderItem] &&
              row[slotHeaderItem][
                page === 'locations'
                  ? 'assignedToLocation'
                  : 'assignedToPatient'
              ].user) ||
            ''
          addRow[headerItem.key] = userName
        })
      })
      setDataToDownload(printData)
    }
  }

  return (
    <CSVLink
      data={dataToDownload}
      filename={page === 'users' ? 'careteam_user_list.csv' : filename}
      headers={headers}
      target="_blank"
      onClick={() => {
        download()
      }}
    >
      {fetchingUserExport ? (
        <Loading square="20px" />
      ) : (
        <CSVPrint
          style={{ backgroundColor: 'white' }}
          color="blue-80"
          fill="flat-white"
        >
          <NeuIcon
            slot="start"
            style={{ height: '30px !important', width: '30px !important' }}
          >
            {page === 'users' ? 'get_app' : 'local_printshop'}
          </NeuIcon>
          {page === 'users' ? (
            <span
              style={{
                fontSize: '16px',
                fontWeight: 'bold !important',
              }}
              onClick={() => {
                if (page === 'users') {
                  logTrackingEvent([
                    {
                      module: Events.Manage.Module,
                      screen: Events.Manage.Screen[0].Name,
                      eventName:
                        Events.Manage.Screen[0].Events.DownloadUserListClick,
                      eventType: 'action',
                      org: {
                        orgId: coId,
                        orgLevel: 'Facility',
                      },
                    },
                  ])
                }
              }}
            >
              Download User List
            </span>
          ) : (
            'CSV'
          )}
        </CSVPrint>
      )}
    </CSVLink>
  )
}

export default PrintCSV
