import CareTeamLogoNavy from '../errorPages/CareTeamLogoNavy.svg'

const Downtime = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: 'white',
          minHeight: '100%',
          minWidth: '100%',
        }}
      />
      <div
        style={{
          top: '30%',
          left: '30%',
          position: 'absolute',
          padding: '50px',
          width: '650px',
          height: '500px',
        }}
      >
        <img
          style={{ paddingLeft: '170px', height: '18.95px' }}
          src={CareTeamLogoNavy}
          alt="Care Team Logo Navy"
        />
        <br />
        <br />
        <h3
          style={{
            paddingLeft: '70px',
            fontSize: '40px',
            fontWeight: 'bold',
            color: 'asphalt',
          }}
        >
          Under Construction
        </h3>
        <p
          style={{
            paddingLeft: '50px',
            color: 'asphalt',
            fontSize: '16px',
          }}
        >
          Changes are coming soon. Please check back later.
        </p>
        <br />
        <br />
        <br />

        <div style={{ paddingLeft: '180px' }}>
          <a href="/">
            <button
              style={{
                height: '40px',
                width: '126px',
                borderRadius: '5px',
                backgroundColor: ' #00558C',
                color: 'white',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              {' '}
              Go Home
            </button>
          </a>
        </div>
      </div>
    </>
  )
}

export default Downtime
