const initialState = { selectedStaffSlots: [] }

export default (state = initialState, action) => {
  switch (action.type) {
    case 'selectedStaffSlots/CLEAR_ALL_SELECTED_LOCATIONS_STAFF':
      return {
        ...state,
        selectedStaffSlots: [],
      }
    case 'selectedStaffSlots/UPDATE_SELECTED_LOCATIONS_STAFF':
      const selectedStaffMember = action.payload
      const updatedSelections = {}
      const selStaffKeys = Object.keys(state.selectedStaffSlots)
      selStaffKeys.forEach((key) => {
        const clonedSelection = Object.assign({}, state.selectedStaffSlots[key])
        clonedSelection.staffMember = selectedStaffMember
        updatedSelections[key] = clonedSelection
      })
      return {
        ...state,
        selectedStaffSlots: updatedSelections,
      }

    case 'selectedStaffSlots/TOGGLE_LOCATION':
      const { buttonHashId, assigned, slot, rowData, page } = action.payload
      const isAlreadySelected = state.selectedStaffSlots[buttonHashId]
      // create shallow copy of state so we can alter it
      const updatedLocationStaffSlots = Object.assign(
        {},
        state.selectedStaffSlots,
      )

      if (isAlreadySelected && assigned) {
        delete updatedLocationStaffSlots[buttonHashId]
      } else if (isAlreadySelected && !assigned) {
        delete updatedLocationStaffSlots[buttonHashId]
      } else if (!isAlreadySelected && assigned) {
        updatedLocationStaffSlots[buttonHashId] = {
          isAssigned: true,
          assignmentData: assigned,
          slot: slot,
          rowData: rowData,
          page: page,
        }
      } else if (!isAlreadySelected && !assigned) {
        updatedLocationStaffSlots[buttonHashId] = {
          isAssigned: false,
          assignmentData: null,
          slot: slot,
          rowData: rowData,
          page: page,
        }
      }

      return {
        ...state,
        selectedStaffSlots: updatedLocationStaffSlots,
      }
    case 'selectedStaffSlots/SELECTED_STAFF_SLOTS':
      return {
        selectedStaffSlots: action.payload,
      }
    default:
      return state
  }
}
