/**
 * External Imports
 * */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
/**
 * Internal Imports
 * */
import './style.css'
import { Bubble, TooltipContainer, TooltipText } from './staff.styles'

const StaffTooltip = ({ staffPool, rowData, cellData }) => {
  const dispatch = useDispatch()
  const [tooltipData, setTooltipData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  /**
   * On mouse enter, filter the bubble metadata on matching ids and dispatch the filtered data
   */
  const handleMouseEnter = () => {
    setIsHovered(true)
    const filteredData = Object.values(staffPool.bubbleMetadata).filter(
      (item) => {
        return item.userId === rowData.id
      },
    )

    dispatch({
      type: 'assignments/SET_FILTERED_BUBBLE_METADATA',
      payload: {
        firstName: rowData.firstName,
        lastName: rowData.lastName,
        assignments: filteredData,
      },
    })
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  /**
   * Map over the filtered bubble metadata and display the first 10 assignments
   */

  const handleTooltipData = () => {
    setIsLoading(false)
    const tooltipData = staffPool.filteredBubbleMetadata.assignments
      ?.slice(0, 10)
      .map((item, index) => (
        <div key={index} style={{}}>
          {item.role} |{' '}
          {item.location.length > 15
            ? `${item.location.substring(0, 15)}...`
            : item.location}{' '}
          |
          {item.patientLastName !== ''
            ? `${item.patientLastName.substring(0, 3)},`
            : ' '}
          {item.patientFirstName !== ''
            ? item.patientFirstName.substring(0, 3)
            : ' '}
        </div>
      ))

    setTooltipData(tooltipData)
  }

  useEffect(() => {
    handleTooltipData()
  }, [staffPool.filteredBubbleMetadata, isHovered])

  const handleAssignmentsModal = () => {
    dispatch({
      type: 'ui/SHOW_ASSIGNMENTS_MODAL',
    })
  }

  return (
    <div>
      <TooltipContainer
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {cellData.rowData.assignmentCount.primary !== 0 && (
          <Bubble type="primary">
            <span>{cellData.rowData.assignmentCount.primary}</span>
          </Bubble>
        )}
        {cellData.rowData.assignmentCount.secondary !== 0 && (
          <Bubble type="secondary">
            <span>{cellData.rowData.assignmentCount.secondary}</span>
          </Bubble>
        )}
        <TooltipText
          style={{ cursor: 'pointer', textOverflow: 'ellipsis' }}
          onClick={handleAssignmentsModal}
        >
          {isLoading ||
          !staffPool.filteredBubbleMetadata.assignments ||
          staffPool.filteredBubbleMetadata.assignments.length === 0 ? (
            <div>Loading...</div>
          ) : (
            <>
              {tooltipData}
              {staffPool.filteredBubbleMetadata.assignments?.length > 10 && (
                <div>
                  + {staffPool.filteredBubbleMetadata.assignments?.length - 10}{' '}
                  more
                </div>
              )}
            </>
          )}
        </TooltipText>
      </TooltipContainer>
    </div>
  )
}

export default StaffTooltip
