const initialState = { rowData: {} }

export default (state = initialState, action) => {
  switch (action.type) {
    case 'slidingPane/GET_ROW_DATA':
      return { ...state, rowData: action.payload }
    default:
      return state
  }
}
