import { combineReducers } from 'redux'
import user from './reducers/user'
import staffPool from './reducers/staffPool'
import locations from './reducers/locations'
import patients from './reducers/patients'
import sites from './reducers/sites'
import units from './reducers/units'
import userGroups from './reducers/groupAndStaffSlotMetaData'
import selectedStaffSlots from './reducers/selectedStaffSlots'
import stagedStaffSlots from './reducers/stagedStaffSlots'
import rowData from './reducers/rowData'
import facilitySchedulerUsers from './reducers/facilitySchedulerUsers'
import ui from './reducers/ui'
import groupAndStaffSlotMetaData from './reducers/groupAndStaffSlotMetaData'
import reports from './reducers/reports'
import addLocations from './reducers/addLocations'
import divisions from './reducers/divisions'
import rooms from './reducers/rooms'
import draftAssignments from './reducers/draftAssignments'

const rootReducer = combineReducers({
  user,
  staffPool,
  locations,
  sites,
  patients,
  units,
  userGroups,
  selectedStaffSlots,
  stagedStaffSlots,
  rowData,
  facilitySchedulerUsers,
  ui,
  groupAndStaffSlotMetaData,
  reports,
  addLocations,
  divisions,
  rooms,
  draftAssignments,
})

export default rootReducer
