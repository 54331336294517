import jsPDF from 'jspdf'
import printChip from './chip'
import px from './px'
import './MarkforHCA-Regular-normal'
import './MarkforHCA-Bold-bold'

export default function pdfReport({ report, headers, fileName, meta }) {
  if (!headers || !report) return
  const doc = new jsPDF({
    orientation: 'l',
    unit: 'mm',
    format: 'letter',
  })

  const snapShotOrder = [
    {
      key: 'unit',
      label: 'Unit',
      xPos: 12.7,
    },
    {
      key: 'bed',
      label: 'Bed',
      xPos: 33,
    },
    {
      key: 'patientName',
      subkey: 'patientMeta',
      label: 'Patient',
      xPos: 60,
    },
    {
      key: 'careTeam',
      label: 'Care Team',
      xPos: 100,
    },
    {
      key: 'lastUpdated',
      label: 'Team Last Updated',
      xPos: 225,
    },
  ]

  const pageMargin = 12.7

  doc.addFont('MarkforHCA-Regular-normal.ttf', 'MarkforHCA-Regular', 'normal')
  doc.addFont('MarkforHCA-Bold-bold.ttf', 'MarkforHCA-Bold', 'bold')

  doc.setFont('MarkforHCA-Bold', 'bold')

  doc.text('Care Team At ' + (meta && meta.reportTime), pageMargin, pageMargin)

  doc.setFont('MarkforHCA-Regular', 'normal').setFontSize(8)

  buildHeaders(headers, snapShotOrder)

  let baseYPos = pageMargin * 2 + px(10)
  let nextYPos = baseYPos

  report.forEach((row, rIndex) => {
    let numOfChipsInRow =
      Math.ceil(row && Array.isArray(row.careTeam) && row.careTeam.length / 4) +
      1
    let currentRowHeight = numOfChipsInRow * px(30)

    if (nextYPos >= 190) {
      doc.addPage()
      buildHeaders(headers, snapShotOrder)
      nextYPos = baseYPos
    }

    generateRow(row, nextYPos, headers)
    nextYPos = nextYPos + currentRowHeight

    // row function should know what yPos
    function generateRow(_row, yPos, _headers) {
      let chipRowCount = 0
      let xPosIndexCount = 0
      _headers.forEach((column, cIndex) => {
        doc.line(column.xPos, yPos - px(10), 270, yPos - px(10))
        // COLUMN
        if (column.key === 'careTeam') {
          _row[column.key].forEach((item, n) => {
            if (n % 4 === 0) {
              chipRowCount++
              xPosIndexCount = 0
            } else {
              xPosIndexCount = n % 4
            }

            let chipHeights = px(27.5) * (chipRowCount - 1)
            printChip({
              doc,
              layout: {
                xPos:
                  xPosIndexCount === 0
                    ? column.xPos
                    : column.xPos + px(125) * xPosIndexCount,
                yPos: yPos + chipHeights,
              },
              displayName: item.teamMember,
              abbreviation: item.slotAbbreviation,
            })
          })
        } else {
          const longText = doc.splitTextToSize(_row[column.key], column.width)
          doc.text(longText, column.xPos, yPos + px(10))
          if (_row[column.subKey]) {
            const longMeta = doc.splitTextToSize(
              _row[column.subKey],
              column.width,
            )
            doc.text(longMeta, column.xPos, yPos + px(25))
          }
        }
      })
    }
  })

  doc.save(fileName)

  function buildHeaders(_headers, order) {
    return _headers.forEach((heading, index) => {
      doc.text(heading.label, heading.xPos, pageMargin * 1.75)
    })
  }
}

/************************************************************
 *  Care Team at xx:xx Today                                 *
 *                                                           *
 *  standard x, absolute y          headers                  *
 *  -------------------------------------------------------  *
 *  standard x, absolute y          row                      *
 *  -------------------------------------------------------  *
 *  standard x, variable y          row                      *
 *  -------------------------------------------------------  *
 *  standard x, variable y          row                      *
 *  -------------------------------------------------------  *
 *  standard x, variable y          row                      *
 *  -------------------------------------------------------  *
 *                                                           *
 *                                                           *
 *************************************************************/
