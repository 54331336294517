export const assignmentCountSort = (
  payload,
  sortDirection,
  nursing,
  isDraftAssignment,
) => {
  if (payload.length < 1) return
  const hasAssignments = []
  const noAssignments = []

  payload.forEach((staff) => {
    const { assignmentCount } = staff
    const { primary, secondary } = assignmentCount !== null && assignmentCount
    const primaryToInt =
      typeof primary === 'number' ? primary : parseInt(primary)
    const secondaryToInt =
      typeof secondary === 'number' ? secondary : parseInt(secondary)
    if (assignmentCount === null || (!primaryToInt && !secondaryToInt)) {
      noAssignments.push(staff)
    } else {
      hasAssignments.push(staff)
    }
  })
  let sortedHasAssignments
  const sortedNoAssignments = []

  if (nursing) {
    const rnAssignments = []
    const lipAssignments = []
    const pctAssignments = []

    hasAssignments.forEach((assignment) => {
      if (
        assignment.clinicalRole &&
        assignment.clinicalRole.systemName ===
          'CTA.ClinicalRoles.RegisteredNurse'
      ) {
        rnAssignments.push(assignment)
      } else if (
        assignment.clinicalRole &&
        assignment.clinicalRole.systemName ===
          'CTA.ClinicalRoles.LicensedPracticalNurse'
      ) {
        lipAssignments.push(assignment)
      } else if (
        assignment.clinicalRole &&
        assignment.clinicalRole.systemName ===
          'CTA.ClinicalRoles.PatientCareTech'
      ) {
        pctAssignments.push(assignment)
      }
    })
    const sortedRn =
      (rnAssignments.length > 0 &&
        rnAssignments.sort((a, b) => {
          const x = a.assignmentCount.primary
          var y = b.assignmentCount.primary
          return x < y ? 1 : x > y ? -1 : 0
        })) ||
      []

    const sortedLip =
      (lipAssignments.length > 0 &&
        lipAssignments.sort((a, b) => {
          const x = a.assignmentCount.primary
          var y = b.assignmentCount.primary
          return x < y ? 1 : x > y ? -1 : 0
        })) ||
      []
    const sortedPct =
      (pctAssignments.length > 0 &&
        pctAssignments.sort((a, b) => {
          const x = a.assignmentCount.primary
          var y = b.assignmentCount.primary
          return x < y ? 1 : x > y ? -1 : 0
        })) ||
      []

    const groupBy = [
      'CTA.ClinicalRoles.RegisteredNurse',
      'CTA.ClinicalRoles.LicensedPracticalNurse',
      'CTA.ClinicalRoles.PatientCareTech',
    ]

    for (let i = 0; i < groupBy.length; i++) {
      for (let j = 0; j < noAssignments.length; j++) {
        if (noAssignments[j].clinicalRole.systemName.trim() === groupBy[i]) {
          sortedNoAssignments.push(noAssignments[j])
        }
      }
    }

    if (sortDirection === 'desc') {
      sortedHasAssignments = [...sortedRn, ...sortedLip, ...sortedPct]
    } else {
      sortedHasAssignments = [
        ...sortedRn.reverse(),
        ...sortedLip.reverse(),
        ...sortedPct.reverse(),
      ]
    }
  } else {
    const sortedNonNursing =
      payload.length > 0 &&
      payload.sort((a, b) => {
        const x = a.assignmentCount && a.assignmentCount.primary
        var y = b.assignmentCount && b.assignmentCount.primary
        return x < y ? 1 : x > y ? -1 : 0
      })
    if (sortDirection === 'desc') {
      sortedHasAssignments = sortedNonNursing
    } else {
      sortedHasAssignments = sortedNonNursing.reverse()
    }
  }
  if (isDraftAssignment) {
    return payload
  } else {
    if (!sortedHasAssignments) {
      return [...sortedNoAssignments]
    } else {
      return [...sortedHasAssignments, ...sortedNoAssignments]
    }
  }
}
