/**
 * External Imports
 * */
import { NeuSpinner } from '@neutron/react'

const Loading = () => {
  return (
    <div
      style={{
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <NeuSpinner color="gray-40" />
    </div>
  )
}

export default Loading
