import styled from 'styled-components'
import './checkbox.scss'

const Checkbox = ({ id, custom, click, isSelected, disabled }) => {
  return (
    <StyledCheckbox
      type="checkbox"
      id={id}
      custom={custom}
      onClick={click}
      disabled={disabled}
    >
      <InnerBox id={id} isSelected={isSelected} disabled={disabled} />
    </StyledCheckbox>
  )
}
export default Checkbox

// This component uses Neutron CSS classes
// loaded from src/neutron directory
// as .scss 'atoms'/'molecules' into App.scss -> App.js

const StyledCheckbox = styled.div.attrs({
  className: 'styledCheckbox',
})`
  width: ${({ custom }) => (custom ? '100%' : 'max-content')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding-left: ${({ custom }) => (custom ? '40%' : '')};
`

const InnerBox = styled.span.attrs(({ isSelected, disabled }) => ({
  className: isSelected
    ? `styledCheckbox__box styledCheckbox__box-is-checked ${
        disabled ? 'styledCheckbox__box-disabled' : ''
      }`
    : `styledCheckbox__box ${disabled ? 'styledCheckbox__box-disabled' : ''}`,
  tabIndex: '0',
  role: 'checkbox',
  'aria-checked': isSelected,
}))`background-color: ${({ isSelected, disabled }) =>
  disabled ? '#B0B0B0' : isSelected ? '#0085ca' : 'transparent'} !important
border: 1px solid ${({ disabled }) => (disabled ? '#B0B0B0' : '#B0B0B0')}
pointer-events: none;
`
