import _ from 'lodash'

export default function formatStaffList(
  staffIds,
  staff,
  selectedStaffMember,
  idsForAssignedStaff,
  isDraftAssignment,
) {
  if (!staff) return []
  //eslint-disable-next-line
  const rawOnShift = staff.filter((s) => {
    return staffIds.some((id) => id === s.hca34)
  })

  let onShift = []
  if (rawOnShift.length > 0) {
    onShift = _.orderBy(
      rawOnShift,
      [
        (nurse) => {
          if (nurse != null) return nurse.lastName.toLowerCase()
        },
      ],
      ['asc'],
    )
  }
  //Filter staff list using ids on all assignments
  const staffWithAssignments = idsForAssignedStaff
    ? staff.filter((s) => idsForAssignedStaff.some((id) => id === s.id))
    : []
  //add actual onshift staff and staff with assignments together
  const allStaffToPrint = isDraftAssignment
    ? onShift
    : onShift.concat(staffWithAssignments)

  return _.uniq(allStaffToPrint) //make sure we aren't duplicating staff from both lists
}
