/**
 * External Imports
 * */
import 'react-app-polyfill/ie9'
import 'babel-polyfill'
import 'core-js'
import { createRoot } from 'react-dom/client'
import { AnalyticsProvider } from '@shared-web-analytics/react/dist'
/**
 * Internal Imports
 * */
import App from './App'
import * as serviceWorker from './serviceWorker'
/**
 * Styling Imports
 * */
import './index.css'
import { StrictMode } from 'react'
import store from './redux/store'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'

const container = document.getElementById('root')
const root = createRoot(container)
const browserHistory = createBrowserHistory()

root.render(
  <StrictMode>
    <AnalyticsProvider>
      <Provider
        className="fch100"
        style={{ height: '100% !important' }}
        store={store}
      >
        <Router style={{ height: '100%' }} history={browserHistory}>
          <App className="fch100" style={{ height: '100% !important' }} />
        </Router>
      </Provider>
    </AnalyticsProvider>
  </StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
