/**
 * The environment variable for the application environment.
 * @type {string}
 */
const environment = process.env.REACT_APP_ENV

if (!environment) {
  throw Error('App environment variable is not set')
}

const serverUrls = {
  local: 'https://careteam-qa.hcahealthcare.cloud/api/',
  devcloud: 'https://careteam-dev.hcahealthcare.cloud/api/',
  qacloud: 'https://careteam-qa.hcahealthcare.cloud/api/',
  prodcloud: 'https://careteam.hcahealthcare.cloud/api/',
  default: 'devurl.com',
}

const _serverUrl = serverUrls[environment.toLowerCase()] || serverUrls.default

const assignEnvs = () => ({
  _reactAppAnalyticsAPIKey: process.env.REACT_APP_ANALYTICS_API_KEY,
  _azureRoleName: process.env.REACT_APP_AZURE_ROLE_NAME,
  _firebaseApiKey: process.env.REACT_APP_FIREBASE_KEY,
  _firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  _firebaseDatabaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  _firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  _firebaseStorageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  _firebaseMessagingId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
  _firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID,
  _firebaseMeasurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
})

const envs = assignEnvs()

/**
 * The exported environment variables.
 * @type {Object}
 */
export default {
  _serverUrl,
  _type: environment.toLowerCase(),
  ...envs,
}
