/**
 * External Imports
 * */
import { NeuButton } from '@neutron/react'
/**
 * Internal Imports
 * */
import { deleteUser } from '../../../redux/actions/user'
/**
 * Styling Imports
 * */
import { FlatTabButton } from '../styleLibrary'
import {
  DeleteUserModal,
  ConfirmDelete,
  ButtonDiv,
} from './deleteUserModal.styles'

export const AcceptDeleteUser = ({
  showDeleteUserModal,
  setShowDeleteUserModal,
  dispatch,
  deleteUserData,
  hideSlidingPane,
}) => {
  const showHideAcceptUserDelete = showDeleteUserModal
    ? 'deleteUserModal display-block'
    : 'deleteUserModal display-none'
  return (
    <div className={showHideAcceptUserDelete}>
      <DeleteUserModal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '10px',
            paddingLeft: '10px',
          }}
        >
          <h6 style={{ fontFamily: 'HCA-Mark-Bold', fontSize: '18px' }}>
            Delete User
          </h6>
          <FlatTabButton onClick={() => setShowDeleteUserModal(false)}>
            <i
              className="neu-avatar__icon material-icons"
              style={{
                color: '#898B8E',
                cursor: 'pointer',
              }}
            >
              cancel
            </i>
          </FlatTabButton>
        </div>
        <div
          style={{
            display: 'flex',
            paddingLeft: '10px',
          }}
        >
          <ConfirmDelete>
            Are you sure you want to delete this user?
          </ConfirmDelete>
        </div>
        <ButtonDiv>
          <NeuButton
            color="danger"
            fill="flat-white"
            style={{ minWidth: '95px' }}
            onClick={() => {
              setShowDeleteUserModal(false)
            }}
          >
            No
          </NeuButton>
          <NeuButton
            color="primary-80"
            fill="raised"
            style={{ minWidth: '95px' }}
            onClick={() => {
              dispatch(deleteUser(deleteUserData))
              setShowDeleteUserModal(false)
              hideSlidingPane()
            }}
          >
            Yes
          </NeuButton>
        </ButtonDiv>
      </DeleteUserModal>
    </div>
  )
}
