import _ from 'lodash'
const initialState = {
  locations: [],
  fetching: false,
  error: null,
  adminLocations: null,
  allLocations: null,
  allUnits: [],
  allBeds: [],
  allRooms: [],
  fetchingAdminLocations: false,
  fetchingAllLocations: false,
  idsForAssignedStaff: [],
  singleLocationToEdit: null,
}

const locations = (state = initialState, action) => {
  switch (action.type) {
    case 'assignments/FETCH_LOCATIONS':
      return { ...state, fetching: true }
    case 'assignments/FETCH_LOCATIONS_ERROR':
      return { ...state, error: action.payload, fetching: false }
    case 'assignments/FETCH_ADMIN_LOCATIONS':
      return { ...state, fetchingAdminLocations: true }
    case 'assignments/FETCH_ADMIN_LOCATIONS_RECEIVED':
      return {
        ...state,
        adminLocations: action.payload,
        fetchingAdminLocations: false,
      }
    case 'locations/FETCH_ALL_LOCATIONS':
      return { ...state, fetchingAllLocations: true }
    case 'locations/FETCH_ALL_LOCATIONS_RECEIVED':
      const filteredLocations = action.payload.data.filter(
        (location) => location.status !== 'Inactive',
      )
      // const filteredSites = filteredLocations.filter(
      //   (site) => site.locationType.displayName === 'Site',
      // )
      const filteredUnits = filteredLocations.filter(
        (unit) =>
          unit.locationType.displayName === 'Unit' &&
          unit.siteId === action.payload.siteId,
      )
      const filteredRooms = filteredLocations.filter(
        (room) =>
          room.locationType.displayName === 'Room' &&
          filteredUnits.some((unit) => unit.id === room.parent.id),
      )
      const filteredBeds = filteredLocations.filter(
        (bed) =>
          bed.locationType.displayName === 'Bed' &&
          filteredRooms.some((room) => room.id === bed.parent.id),
      )
      return {
        ...state,
        allLocations: filteredLocations,
        allUnits: filteredUnits,
        allRooms: filteredRooms,
        allBeds: filteredBeds,
        fetchingAllLocations: false,
      }
    case 'locations/CLEAR_ALL_LOCATIONS':
      return {
        ...state,
        allLocations: null,
        allUnits: [],
        allRooms: [],
        allBeds: [],
        fetchingAllLocations: false,
      }
    case 'locations/GET_SINGLE_LOCATION':
      return {
        ...state,
        singleLocationToEdit: action.payload,
      }
    case 'locations/CLEAR_SINGLE_LOCATION':
      return {
        ...state,
        singleLocationToEdit: null,
      }

    case 'assignments/FETCH_LOCATIONS_RECEIVED':
      return {
        ...state,
        locations: _.uniq(action.payload.activeLocations),
        fetching: false,
        idsForAssignedStaff: action.payload.uniqIdsOfStaffWithAssignments,
      }

    default:
      return state
  }
}

export default locations
