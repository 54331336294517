/**
 * @description All Events will be written inside this file. Format for this event are as follows
 */

const Events = {
  Locations: {
    Module: 'Location Tab',
    Screen: [
      {
        Name: 'Current',
        Events: {
          CurrentTabClick: 'CurrentTabClick',
          DeptDropdownMenuClick: 'DeptDropdownMenuClick',
          UnitDropdownMenuClick: 'UnitDropdownMenuClick',
          StaffPoolSearchFieldClick: 'StaffPoolSearchFieldClick',
          StaffMemberSelectClick: 'StaffMemberSelectClick',
          UnitPatientSearchFieldClick: 'UnitPatientSearchFieldClick',
          AssignButtonClick: 'AssignButtonClick',
          UnassignButtonClick: 'UnassignButtonClick',
          PublishButtonClick: 'PublishButtonClick',
        },
      },
      {
        Name: 'New Draft', // all draft working
        Events: {
          NewDraftTabClick: 'NewDraftTabClick',
          DeptDropdownMenuClick: 'DeptDropdownMenuClick',
          UnitDropdownMenuClick: 'UnitDropdownMenuClick',
          StaffPoolSearchFieldClick: 'StaffPoolSearchFieldClick',
          StaffMemberSelectClick: 'StaffMemberSelectClick',
          UnitPatientSearchFieldClick: 'UnitPatientSearchFieldClick',
          AssignButtonClick: 'AssignButtonClick', 
          UnassignButtonClick: 'UnassignButtonClick', 
          PublishButtonClick: 'PublishButtonClick', 
      },
    }
    ],
  },
  Patients: {
    Module: 'Patient Tab',
    Screen: {
      Name: 'Patient',
      Events: {
        PatientTabClick: 'PatientTabClick',
        DeptDropdownMenuClick: 'DeptDropdownMenuClick',
        UnitDropdownMenuClick: 'UnitDropdownMenuClick',
        StaffPoolSearchFieldClick: 'StaffPoolSearchFieldClick',
        StaffMemberSelectClick: 'StaffMemberSelectClick',
        UnitPatientSearchFieldClick: 'UnitPatientSearchFieldClick',
        AssignButtonClick: 'AssignButtonClick',
        UnassignButtonClick: 'UnassignButtonClick',
        PublishButtonClick: 'PublishButtonClick', 
      },
    },
  },
  Reports: {
    Module: 'Reports Tab',
    Screen: [
      {
        Name: 'Snapshot',
        Events: {
            SnapshotReportClick: 'SnapshotReportClick',
            UnitDropdownClick: 'UnitDropdownClick',
            PositionDropdownClick: 'PositionDropdownClick',
            DateFieldClick: 'DateFieldClick',
            TimeHourClick: 'TimeHourClick',
            TimeMinuteClick: 'TimeMinuteClick',
            KeyWordClick: 'KeyWordClick',
            CancelButtonClick: 'CancelButtonClick',
            RunReportClick: 'RunReportClick',
        },
      },
      {
        Name: 'AssignmentAudit',
        Events: {
            AssignmentAuditReportClick: 'AssignmentAuditReportClick',
            UnitDropdownClick: 'UnitDropdownClick',
            PositionDropdownClick: 'PositionDropdownClick',
            TimeClick: 'TimeClick',
            KeyWordClick: 'KeyWordClick',
            CancelButtonClick: 'CancelButtonClick',
            RunReportClick: 'RunReportClick',
        },
      },
      {
        Name: 'MissingAssignments',
        Events: {
          MissingAssignmentReportClick: 'MissingAssignmentReportClick',
          UnitDropdownClick: 'UnitDropdownClick',
          PositionDropdownClick: 'PositionDropdownClick',
          TimeClick: 'TimeClick',
          CancelButtonClick: 'CancelButtonClick',
          RunReportClick: 'RunReportClick',
        },
      },
    ],
  },
    Manage: {
      Module: 'Manage Tab',
      Screen: [
        {
          Name: 'Users',
          Events: {
            UsersTabClick: 'UsersTabClick',
            DivisionDropdownMenuClick: 'DivisionDropdownMenuClick',
            FacilityDropdownMenuClick: 'FacilityDropdownMenuClick',
            RoleDropdownMenuClick: 'RoleDropdownMenuClick',
            DownloadUserListClick: 'DownloadUserListClick',
          },
        },
        {
          Name: 'Locations',
          Events: {
            LocationsTabClick: 'LocationsTabClick',
            DivisionDropdownMenuClick: 'DivisionDropdownMenuClick',
            FacilityDropdownMenuClick: 'FacilityDropdownMenuClick',
            UnitsDropdownMenuClick: 'UnitsDropdownMenuClick',
            RoomsDropdownMenuClick: 'RoomsDropdownMenuClick',
            StatusDropdownMenuClick: 'StatusDropdownMenuClick',
          },
        },
        {
          Name: 'Patients',
          Events: {
            PatientsTabClick: 'PatientsTabClick',
            DivisionDropdownMenuClick: 'DivisionDropdownMenuClick',
            FacilityDropdownMenuClick: 'FacilityDropdownMenuClick',
            UnitsDropdownMenuClick: 'UnitsDropdownMenuClick',
            SearchFieldClick: 'SearchFieldClick',
            RemoveButtonClick: 'RemoveButtonClick',
          },
        },
      ],
    },
}
export default Events;

