import axios from '../../utils/axiosWrapper'
import environment from '../../environment'
import ErrorCheck from '../../utils/errorCheck'

const serverUrl = environment._serverUrl

export const getClinicalRoles =
  ({ authorization, siteId }) =>
  (dispatch) => {
    const url = serverUrl + 'Metadata/ClinicalRoles'

    dispatch({ type: 'metadata/FETCHING_CLINICAL_ROLES', payload: true })
    axios({
      method: 'get',
      url,
      headers: {
        authorization,
        siteId,
        Pragma: 'no-cache',
      },
    })
      .then((res) => {
        const clinicalRolesWithoutetag = []
        res.data.forEach((role) => {
          delete role._etag
          clinicalRolesWithoutetag.push(role)
        })
        dispatch({ type: 'metadata/FETCHING_CLINICAL_ROLES', payload: false })
        dispatch({
          type: 'metadata/FETCHING_CLINICAL_ROLES_RECEIVED',
          payload: clinicalRolesWithoutetag,
        })
      })
      .catch((err) => {
        dispatch({ type: 'metadata/FETCHING_CLINICAL_ROLES', payload: false })
        dispatch({
          type: 'metadata/FETCHING_CLINICAL_ROLES_ERROR',
          payload: err,
        })
        console.log(err)
      })
  }

export const getClinicalRolesForAddUser =
  ({ authorization, siteId }) =>
  (dispatch) => {
    const url = serverUrl + 'Metadata/ClinicalRoles'

    dispatch({
      type: 'metadata/FETCHING_CLINICAL_ROLES_FOR_ADD_USER',
      payload: true,
    })
    axios({
      method: 'get',
      url,
      headers: {
        authorization,
        siteId,
        Pragma: 'no-cache',
      },
    })
      .then((res) => {
        dispatch({
          type: 'metadata/FETCHING_CLINICAL_ROLES_FOR_ADD_USER',
          payload: false,
        })
        dispatch({
          type: 'metadata/FETCHING_CLINICAL_ROLES_FOR_ADD_USER_RECEIVED',
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: 'metadata/FETCHING_CLINICAL_ROLES_FOR_ADD_USER',
          payload: false,
        })
        dispatch({
          type: 'metadata/FETCHING_CLINICAL_ROLES_FOR_ADD_USER_ERROR',
          payload: err,
        })
        console.log(err)
      })
  }

export const fetchUserGroups = (authToken, siteId) => (dispatch) => {
  const urlForAuthUser = serverUrl + 'Metadata/ViewConfiguration'

  dispatch({ type: 'metadata/FETCH_USER_GROUPS' })
  axios({
    method: 'get',
    url: urlForAuthUser,
    headers: {
      authorization: authToken,
      siteId,
      Pragma: 'no-cache',
    },
  })
    .then(function (response) {
      dispatch({
        type: 'metadata/FETCH_USER_GROUPS_RECEIVED',
        payload: response.data,
      })
    })
    .catch(function (error) {
      ErrorCheck(error)
      dispatch({
        type: 'metadata/FETCH_USER_GROUPS_ERROR',
        payload: error.response,
      })
    })
}

export const selectUserGroup = (userGroup) => (dispatch) => {
  dispatch({
    type: 'metadata/SET_SELECTED_USER_GROUP',
    payload: userGroup,
  })
}

export const fetchLockedRoles = (authToken, siteId) => (dispatch) => {
  const url = serverUrl + `Metadata/LockedRoles/${siteId}`

  dispatch({ type: 'metadata/FETCH_LOCKED_ROLES_REQUEST', payload: true })
  axios({
    method: 'get',
    url,
    headers: {
      authorization: authToken,
      Pragma: 'no-cache',
    },
  })
    .then(function (response) {
      dispatch({
        type: 'metadata/FETCH_LOCKED_ROLES_SUCCESS',
        payload: response.data,
      })
    })
    .catch(function (error) {
      ErrorCheck(error)
      dispatch({
        type: 'metadata/FETCH_LOCKED_ROLES_ERROR',
        payload: error.response ? error.response.data : 'Unknown Error',
      })
    })
}

export const fetchLockedRoleConfig = (authToken, siteId) => (dispatch) => {
  const url = `${serverUrl}Metadata/LockedRoleConfiguration/${siteId}`

  dispatch({
    type: 'metadata/FETCH_LOCKED_ROLES_CONFIG_REQUEST',
    payload: true,
  })
  axios({
    method: 'get',
    url,
    headers: {
      authorization: authToken,
      Pragma: 'no-cache',
    },
  })
    .then(function (response) {
      dispatch({
        type: 'metadata/FETCH_LOCKED_ROLES_CONFIG_SUCCESS',
        payload: response.data,
      })
    })
    .catch(function (error) {
      ErrorCheck(error)
      dispatch({
        type: 'metadata/FETCH_LOCKED_ROLES_CONFIG_ERROR',
        payload: error.response ? error.response.data : 'Unknown Error',
      })
    })
}

export const clearLockedRoles = () => ({
  type: 'metadata/CLEAR_LOCKED_ROLES',
})
